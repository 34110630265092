import React, { useState } from 'react';
import '../css/connexion.css';
import 'bootstrap/dist/css/bootstrap.css';
import { BiSolidUser } from 'react-icons/bi'
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function Connexion() {
  const [email, setEmail] = useState('');
  const [mot_de_passe, setMot_de_passe] = useState('');
  const navigate = useNavigate('');
  const [error, setError]= React.useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('Enregistrement:', email, mot_de_passe);
  }

  const login = () => {
    if (mot_de_passe !== "" && email !== ""){
    axios
      .post(
        "http://localhost:100/user/login",
        {
         email:email,
         mot_de_passe:mot_de_passe,
        }
      )
      .then((res) => {
        if(res.data.error){
          setError("Mot de passe incorrect");
        }else {
          setError("");
          sessionStorage.setItem("accessToken", res.data.accessToken);
          navigate("/naveba")
        }
      })
      
      .catch((error) => {
        console.log(error);
      });
    }
  }

  return (

    <div className='row'>
      <div className='col-sm-6'>
        <img src="./images/log.jpg" alt='responsive' className='image' />
      </div>
      <div className='col-sm-6'>
        <div>
        <BiSolidUser className='bi' />
        <form onSubmit={handleSubmit}>
          <div className='inputboxess'>
            <input type='text' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Entrer votre e-mail' />
          </div>
          <div className='inputboxe'>
            <input type='password' name='mot_de_passe' value={mot_de_passe} onChange={(e) => setMot_de_passe(e.target.value)} placeholder='Mot de passe' />
          </div>
          <div className='bt'>

              <button type="submit" className='btn btn-success' onClick={()=> {login();}}> Se connecter</button>

          </div>
          <div className='P2'>
            <p className='compte'> Vous n'avez pas de compte? <Link to= '/inscriptionF' className='connect'>S'inscrire</Link></p>
          </div>
        </form>
        </div>
      </div>
    </div>

  )
}
export default Connexion;
