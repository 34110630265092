import React from 'react';
import '../csssite/contact.css';
import { Naves } from './nav';
import {Foot} from './foot';

export const Contact = () => {
  return (
    <div>
      <div>
        <Naves/>
      </div>
      <div className='contact'>
        <h3 className='nou'>CONTACTEZ-NOUS</h3>
        <div className='cont'>
          <div className='row cardes'>
             <div className='col-sm-6'>
                <b>HEURE DE LIVRAISON</b>
                <p>Lundi à Samedi
                <br></br>Matin<br></br>08h à 12h
                <br></br>Après-midi<br></br>14h30 à 18h</p>
             </div>
             <div className='col-sm-6'>
                <p className='text-primary'>contactnatura@gmail.com</p>
                <p>034566765<br></br>098765433<br></br>034222223</p>
             </div>
          </div>
              <div className='row dv'>
                  <div className='col-sm-5'>
                    <input placeholder='Nom' className='inpn'/>
                  </div>
                  <div className='col-sm-5'>
                    <input placeholder='E-mail' className='inpn'/>
                  </div>
              </div>
              <div className='row'>
                  <div className='col-sm-6'>
                    <textarea placeholder='Entrer votre message' className='in'/>
                  </div>
                  <div className='col-sm-6'>
                    <button className='btn-en'>Envoyer</button>
                  </div>
              </div>
        </div>
      </div>
      <div>
        <Foot/>
      </div>
    </div>
  )
}
