import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import "../cssfront/nave.css";
import { Nav} from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Navba extends Component {
  state = {clicked : false};
  handleClick = () =>{
    this.setState({clicked:!this.state.clicked})
  }
  render(){
    const iconColor = this.state.clicked ? 'rgb(29, 230, 46)' : 'rgb(29, 230, 46)';
    const logoStyle = {
      width: '110px', // Largeur de l'image
      marginTop: '10px',
      marginLeft:'2%',
    };
  return (
    <div>
    <Nav>
      <Link to="/logo">
       <img src="./images/log.jpg" alt='responsive' style={logoStyle}></img> 
       </Link>
      <div>
        <ul id='navbar' className={this.state.clicked ? "#navbar active" : "#navbar"}>

          <li> <Link to="/profilAgri" className="active link_to">Agriculteur</Link></li>
          <li><Link to='/message' className='link_to'>Message</Link></li>
          <li><Link to='/notification' className='link_to'>Notification</Link></li>
          <li className="dropdown"> Publication
             <div className="dropdown-content">
                <Link to="/ajoutopportunite" className="link_to">Opportunité</Link>
                <Link to="/ajoutshop" className="link_to">Shop</Link>
                <Link to="/ajoutlocation"  className='link_to'>Location</Link>
                <Link to="/ajoutpartenaire" className='link_to'>Partenaire</Link>
                <Link to="/categorie" className='link_to'>Catégorie</Link>
                <li><Link to='/' className='link_to'>Compte</Link></li>
              </div>
          </li>
          <li><Link to='/' className='link_to'>Se déconnecter</Link></li>

        </ul>
      </div>
      <div id="mobile" onClick={this.handleClick}>
        <FontAwesomeIcon icon={this.state.clicked ? faTimes :
        faBars} style={{ marginLeft: '-100%', marginTop:'50%' ,cursor: 'pointer', fontSize: '27px', color: iconColor}}/>
      </div>
    </Nav>
  </div>
  )
}
}
export default Navba;