import React from 'react';
import Navbare from './navbar';
import '../css/logo.css';

export const Logo = () => {
  
  return (
    <div>
      <Navbare/>
      <div className='til'>
      <h1>DASHBOARD</h1>
      <a href='/accueil'>Voir le site web </a>
      </div>
    </div>
  );
};

