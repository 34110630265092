import React, { useState, useEffect } from 'react'
import Navbare from '../navbar';
import '../../css/cooperative.css';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from 'axios';
import url from '../../url';

export const Cooperative = () => {

    const [code, setCode] = useState('');
    const [cooperative, setCooperative] = useState('');
    const [records, setRecords] = useState([]);
    const [cooperatives, setCooperatives] = useState([]);
    const [editingData, setEditingData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
    }


    const handleInput = (e) => {
        const { name, value } = e.target;
        setEditingData({ ...editingData, [name]: value });
    };

    const handleAdd = () => {
        if (isEditing) {

            axios
                .put(`http://localhost:100/cooperative/${editingData.id}`, {
                    code_cooperative: editingData.code_cooperative,
                    cooperative: editingData.cooperative,
                })
                .then((res) => {
                    const updatedCooperatives = cooperatives.map((cooperative) =>
                        cooperative.id === editingData.id ? editingData : cooperative
                    );
                    setCooperatives(updatedCooperatives);
                    setIsEditing(false);
                    setEditingData({
                        code_cooperative: '',
                        cooperative: '',
                    });
                    window.alert("Mise à jour réussie");
                    afficheCooperative();
                })
                .catch((error) => {
                    console.error("Erreur lors de la mise à jour :", error);
                    window.alert("Erreur lors de la mise à jour : " + error.message);
                });
        } else {

            axios
                .post(`http://localhost:100/cooperative/`, {
                    code_cooperative: code,
                    cooperative: cooperative,
                })
                .then((res) => {
                    setCooperatives([...cooperatives, res.data]);
                    alert("Ajout avec succès");
                    afficheCooperative();
                    setCode("");
                    setCooperative("");
                })
                .catch((error) => {
                    console.error("Erreur lors de l'ajout :", error);
                    window.alert("Erreur lors de l'ajout : " + error.message);
                });
        }
    };

    const CustomActions = ({ row }) => {

        const handleDelete = (id) => {
            const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
            if (confirmDelete) {
                axios
                    .delete(`http://localhost:100/cooperative/${id}`)
                    .then(() => {
                        // const updatedCooperatives = cooperatives.filter((cooperative) =>
                        //     cooperative.id !== id
                        // );
                        // setCooperatives(updatedCooperatives);
                        alert("Suppression réussie");
                        afficheCooperative();
                    })
                    .catch((error) => {
                        console.error("Erreur lors de la suppression :", error);
                        window.alert("Erreur lors de la suppression : " + error.message);
                    });
            }
        };
        const handleEdit = (row) => {
            setEditingData(row);
            setIsEditing(true);
        };

        return (
            <div>
                <button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
                <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
            </div>
        );
    };

    const columns = [
        {
            name: 'COOPERATIVE',
            selector: (row) => row.cooperative,
            sortable: true,
        },
        {
            name: "CODE",
            selector: (row) => row.code_cooperative,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <CustomActions row={row} />,
        },
    ];

    const afficheCooperative = async () =>{
        url
        .get("/cooperative/")
        .then((res)=> setRecords(res.data))
        .catch((err) => console.log(err));
    };
    useEffect(()=>{
        afficheCooperative();
    },[]);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <Navbare />
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-2 top">
                            <div className="col-sm-6">
                                <p className="m-0 paramet">AJOUT COOPERATIVE</p> <br></br>
                                {editingData ? (
                                    <>
                                        <div className='lab'>
                                            <label>CODE:</label>
                                            <input className='joutes' type='number' name='code_cooperative' value={editingData.code_cooperative} onChange={(handleInput)} />
                                        </div>
                                        <div className='lab'>
                                            <label>COOPERATIVE :</label>
                                            <input className='joue' name='cooperative' value={editingData.cooperative} type='text' onChange={handleInput} />
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='lab'>
                                            <label>CODE:</label>
                                            <input className='joutes' type='number' name='code' value={code} onChange={(e) => setCode(e.target.value)} />
                                        </div>
                                        <div className='lab'>
                                            <label>COOPERATIVE :</label>
                                            <input className='joue' name='cooperative' value={cooperative} type='text' onChange={(e) => setCooperative(e.target.value)} />
                                        </div>
                                    </>
                                )}

                                <div className='btn-partes'>
                                    <button className='btn btn-primary' type='submit' onClick={handleAdd} > {isEditing ? "Modifier": "Ajouter"} </button>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className='parametre'>
                                    <p>LISTE DES COOPERATIVES</p>
                                </div>
                                <div className='tabl'>
                                    <DataTable
                                        columns={columns}
                                        data={records}
                                        pagination
                                    // highlightOnHover
                                    /></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <Footer/> */}
        </div>

    )
}
