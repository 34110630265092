import React, { useState, useEffect } from 'react'
import Navbare from './navbar'
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import axios from 'axios';
import url from '../url';

export const Ajoutpartenaire = () => {

  const [denomination, setDenomination]=useState('');
  const [site, setSite]=useState('');
  const [logo, setLogo]=useState('');
  const [description, setDescription]=useState('');
  const [records, setRecords]=useState([]);
  const [partenaires, setPartenaires] = useState('');

  const handleSubmit =(e) =>{
    e.preventDefault();
  };

  
  const ajouterPartenaire = () =>{
    const formdata = new FormData();
    formdata.append("denomination", denomination);
    formdata.append("logo", logo);
    formdata.append("description", description);
    formdata.append("site_web", site);

    axios
    .post(
      "http://localhost:100/partenaire", formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      setPartenaires([...partenaires, res.data]);
      handleClose();
      alert("Ajout avec succès");
      affichePartenaire();
      setDenomination("");
      setDescription("");
      setLogo("");
      setSite("");
    })
    .catch((error) => {
      console.log(error);
      // Gérer l'erreur ici
    });

  }

  const CustomActions = ({ row }) => {
    const [showmodal, setShowmodal] = useState(false);

    const handleClosemodal = () => setShowmodal(false);

    const handleEdit = () => {
      setShow(true);
    };

    const handleDelete = (id) => {
      const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
      if (confirmDelete) {
        axios
          .delete(`http://localhost:100/partenaire/${id}`)
          .then((response) => {
              alert("Suppression reussi");
              affichePartenaire();
            })
          .catch((error) => {
            console.error("Erreur lors de la suppression :", error);
            window.alert("Erreur lors de la suppression : " + error.message);
          });
      }
    };

    return (
      <div>
        <button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
        <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
      </div>
    );
  };

  const columns = [
    {
      name: 'Dénomination',
      selector: (row) => row.denomination,
      sortable: true,
    },
    {
      name: "Site web",
      selector: (row) => row.site_web,
      sortable: true,
    },
    {
      name: 'Logo',
      selector: (row)=> row.logo,
      sortable: true,
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => <CustomActions row={row} />,
    },
  ];
  const affichePartenaire = async () => {
    url
      .get("/partenaire/")
      .then((res) => {
        setRecords(res.data)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    affichePartenaire();
  }, []);


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <Navbare />
            <div className="row mb-2 top">
              <div className="col-sm-4 liste">
                <h3 className="m-0 ">LISTE DES PARTENAIRES</h3>
              </div>
              <div className="col-sm-3">
                <ol className="breadcrumb float-sm-right reche">
                  <input
                    type="text"
                    name="recherche"
                    // onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
              <div className="col-sm-3">
                <button className='btn btn-primary' onClick={handleShow} type='button'>+ Ajouter</button>
                <Modal show={show} onHide={handleClose} >
                  <form onSubmit={handleSubmit}>
                  <Modal.Header closeButton className='header'>
                    <Modal.Title className='text-primary'>AJOUT PARTENAIRE</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='container row'>
                      <Form.Group className="col-sm-6 mb-3">
                        <div>
                          <input
                            type="text"
                            name='denomination'
                            value={denomination}
                            onChange={(e)=>setDenomination(e.target.value)}
                            placeholder='Dénomination'
                            className='form-control'
                            autoFocus
                          /></div>
                      </Form.Group>
                      <Form.Group className="col-sm-6 mb-3">
                        <div>
                          <input type="text" name='site' value={site} onChange={(e) => setSite(e.target.value)} placeholder='Site web' className='form-control' />
                        </div>
                      </Form.Group>
                    </div>
                    <div className='container row'>
                      <Form.Group className="mb-3">
                        <div>
                          <input
                            type="file"
                            placeholder='Image'
                            name='image'
                            onChange={(e) => {
                              setLogo(e.target.files[0]);
                            }}
                            className='form-control'
                            autoFocus
                          /></div>
                      </Form.Group>
                    </div>
                    <Form.Group className="mb-3">
                      <div>
                        <textarea
                          type="text"
                          placeholder='Description'
                          name='descriprion'
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                          className='form-control'
                          autoFocus
                        /></div>
                    </Form.Group>
                  </Modal.Body>
                  <Modal.Footer className='footer'>
                    <button className='btn-primary' onClick={ajouterPartenaire}>
                      Publier
                    </button>
                  </Modal.Footer>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='data'>
          <DataTable
            columns={columns}
            data={records}
            pagination
          // highlightOnHover
          /></div>
      </div>
    </div>
  )
}
