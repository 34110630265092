import React, { useState } from 'react'
import { Naves } from './nav';
import { Foot } from './foot'
import "../csssite/compte.css";
import { AiTwotoneEdit } from "react-icons/ai";
import {BiSave} from 'react-icons/bi';

export const Compte = () => {
  const initialUserInfo = {
    nom: 'John Doe',
    email: 'john.doe@example.com',
    tel: '04536213724',
    adresse: 'tsenegea',
    mdp: 'hsfhsfjjds'
    // Ajoutez d'autres champs d'informations de l'utilisateur ici
  };

  const [userInfo, setUserInfo] = useState(initialUserInfo);
  const [editingField, setEditingField] = useState(null);

  const handleEdit = (field) => {
    setEditingField(field);
  };

  const handleSave = () => {
    // Mettez en œuvre la logique de sauvegarde ici
    setEditingField(null); // Désactivez le mode édition après la sauvegarde
  };


  return (
    <>
      <Naves />
      <div className="body_compte">
        <div className='cardess'>
          <h6 className='info_perso'>INFORMATION PERSONNELLE</h6>
          <div className='liste_info'>
              {Object.keys(userInfo).map((field) => (
                <p key={field}>
                  <span><b>{field} : </b>  </span>
                  {editingField === field ? (
                    <input
                    className='input-edit'
                      type="text"
                      value={userInfo[field]}
                      onChange={(e) =>
                        setUserInfo({ ...userInfo, [field]: e.target.value })
                      }
                    />
                  ) : (
                    <span>{userInfo[field]}</span>
                  )}
                  {editingField === field ? (
                    <BiSave onClick={handleSave} className='button-save'/>
                  ) : (
                    < AiTwotoneEdit className='aitwo' onClick={() => handleEdit(field)} />
                  )}
                </p>
              ))}
           
          </div>
        </div>
      </div>
      <Foot />
    </>
  )
}
