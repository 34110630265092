import React, { useState, useEffect } from 'react'
import Navbare from './navbar';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import '../css/ajoutshop.css';
import url from '../url';


export const Ajoutshop = () => {

  const [nom, setNom] = useState('');
  const [prix, setPrix] = useState('');
  const [detail, setDetail] = useState('');
  const [image, setImage] = useState('');
  const [records, setRecords] = useState([]);
  const [shops, setShops] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const ajoutéShop = () => {
    const formdata = new FormData();
    formdata.append("title", nom);
    formdata.append("price", prix);
    formdata.append("image", image);
    formdata.append("detail", detail);
    formdata.append("id_categorie", selectedOption);

    axios
      .post(
        "http://localhost:100/shop", formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setShops([...shops, res.data]);
        handleClose();
        alert("Ajout avec succès");
        afficheShop();
        setDetail("");
        setImage("");
        setNom("");
        setPrix("");
        setSelectedOption("");
      })
      .catch((error) => {
        console.log(error);
        // Gérer l'erreur ici
      });

  }


  const CustomActions = ({ row }) => {

    const handleEdit = () => {
      setShow(true);
    };

    const handleDelete = (id) => {
      const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
      if (confirmDelete) {

        axios
          .delete("http://localhost:100/shop/" + id)
          .then(() => {
            const updatedShops = Array.isArray(shops) ? shops.filter((shop) => shop.id !== id) : [];
            setShops(updatedShops);

            window.alert("Suppression réussie");
            afficheShop();
          })
          .catch((error) => {
            console.error("Erreur lors de la suppression :", error);
            window.alert("Erreur lors de la suppression : " + error.message);
          });
      }
    };

    return (
      <div>
        <button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
        <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
      </div>
    );
  };

  const columns = [
    {
      name: 'Nom',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Prix",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Détails",
      selector: (row) => row.detail,
      sortable: true,
    },
    {
      name: "Catégorie",
      selector: (row) => row.categories.categorie,
      sortable: true,
    },
    {
      name: 'Photo',
      selector: (row) => row.image,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => <CustomActions row={row} />,
    },
  ];

  const afficheShop = async () => {
    url
      .get("/shop/")
      .then((res) => {
        setRecords(res.data)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    afficheShop();
  }, []);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [selectedOption, setSelectedOption] = useState('');
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/categorie");
        setCategories(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des options : ", error);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <Navbare />
            <div className="row mb-2 top">
              <div className="col-sm-4 liste">
                <h3 className="m-0 ">LISTE DES SHOP</h3>
              </div>
              <div className="col-sm-3">
                <ol className="breadcrumb float-sm-right reche">
                  <input
                    type="text"
                    name="recherche"
                    // onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
              <div className="col-sm-3">
                <button className='btn btn-primary' onClick={handleShow} type='button'>+ Ajouter</button>
                <Modal show={show} onHide={handleClose}>
                  <form onSubmit={handleSubmit}>
                    <Modal.Header closeButton className='header'>
                      <Modal.Title className='text-primary'>AJOUT SHOP</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='row'>
                        <Form.Group className="col-sm-6 mb-3">
                          <div>
                            <input
                              type="text"
                              name='nom'
                              value={nom}
                              onChange={(e) => setNom(e.target.value)}
                              placeholder='Nom'
                              className='form-control'
                              autoFocus
                            /></div>
                        </Form.Group>
                        <Form.Group className="col-sm-6 mb-3">
                          <div>
                            <input
                              className='form-control'
                              type="number"
                              name='prix'
                              value={prix}
                              onChange={(e) => setPrix(e.target.value)}
                              placeholder='Prix'
                              autoFocus
                            /></div>
                        </Form.Group>
                      </div>
                      <div className='row'>

                        <Form.Group className="col-sm-6 mb-3">
                          <div>
                            <input type="file" placeholder='Image' name='image' className='form-control' onChange={(e) => {
                              setImage(e.target.files[0]);
                            }} />
                          </div>
                        </Form.Group>

                        <Form.Group className="col-sm-6 mb-3">
                          <div>
                            <label>Catégorie:</label>
                            <select value={selectedOption} onChange={handleSelectChange} className='form-control'>
                              <optio> </optio>
                              {categories.map((option) => (
                                <option key={option.id} value={option.id}>
                                  {option.categorie}
                                </option>
                              ))}
                            </select>
                          </div>
                        </Form.Group>
                      </div>
                      <div className='container'>
                        <Form.Group className="mb-3">
                          <div>
                            <textarea
                              className='form-control'
                              name='detail'
                              value={detail}
                              onChange={(e) => setDetail(e.target.value)}
                              type="text"
                              placeholder='Détails'
                              autoFocus
                            /></div>
                        </Form.Group>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className='footer'>
                      <button className='btn-primary' type='submit' onClick={ajoutéShop}>
                        Publier
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='data'>
          <DataTable
            columns={columns}
            data={records}
            pagination
          // highlightOnHover
          /></div>
      </div>
    </div>
  )
}
