import React from 'react'
import 'bootstrap/dist/css/bootstrap.css';
import { BsInstagram, BsTwitter, BsFacebook } from 'react-icons/bs'
import "../csssite/foot.css"

export const Foot = () => {
  return (
    <>
      <div className='Footer'>
        <div className='container_footer'>
          <div className='container'>
            <div className='bloc'>
              <div className='container_footer1 row'>
                <div className='col-lg-3 col-md-6 div'>
                  <img className='logolong' alt='responsive' src='../../images/log.jpg' />
                  <div className='mg'>
                    <p>Découvrez l'excellence culinaire avec </p>
                    <p style={{ fontSize: '155%' }}>MIHARO,</p>
                    <p>TANTSAHA MANDROSO</p>
                  </div>
                </div>
                <div className='service col-lg-3 col-md-6 div aprop'>
                  <p>À propos</p>
                  <a href='/'>Concept</a>
                  <a href='/'>Franchise</a>
                  <a href='/'>Business</a>
                </div>
                <div className='ressource col-lg-3 col-md-6 div pour'>
                  <p>Pour vous aider</p>
                  <a href='/'>FAQs</a>
                  <a href='/'>Spécialités</a>
                </div>
                <div className='contact col-lg-3 col-md-6 div cord'>
                  <p>Nos Coordonnées</p>
                  <span className='lieu'>
                    <span className='text_lieu'>Tana-Mahazoarivo </span>
                  </span>
                  <span className='lot'>
                    <span className='text_lot'>VK 17 Fenomanana</span>
                  </span>
                  <span className='phone'>
                    0800 111 126
                  </span>
                  <span className='mail'>natura@gmail.com</span>
                </div>
              </div>
              {/* <hr/> */}
              <div className='lien_logo'>
                <span className='c'><a href='/'><BsInstagram /></a></span>
                <span className='b'><a href='/'><BsTwitter /></a></span>
                <span className='b'><a href='/'><BsFacebook /></a></span>
                <div className='condition'>
                  <a href='/'>Conditions d'utilisations</a>
                </div>
                <div className='terme'>
                  <a href='/'>Termes et conditions</a>
                </div>
              </div>
            </div>
          </div>
          <div className='container_footer2 row'>
            <div className='copyright col'>
              <p className='cop'>ⓒ Copyright HAISOA. Tous droits réservés, conçue par <a style={{ color: 'white' }} href='/'>HAISOA</a></p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};


