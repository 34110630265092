import React, { useState, useEffect } from 'react'
import Navbare from '../navbar';
import '../../css/province.css';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from 'axios';
import url from '../../url';

export const Province = () => {

    const [code, setCode] = useState('');
    const [province, setProvince] = useState('');
    const [records, setRecords] = useState([]);
    const [provinces, setProvinces] = useState('');
    const [editingData, setEditingData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
    }

    const handleInput = (e) => {
        const { name, value } = e.target;
        setEditingData({ ...editingData, [name]: value });
    };


    const ajouterProvince = () =>{
        if (isEditing) {

            axios
                .put(`http://localhost:100/province/${editingData.id}`, {
                    code_province: editingData.code_province,
                    province: editingData.province,
                })
                .then((res) => {
                    setIsEditing(false);
                    setEditingData({
                        code_province: '',
                        province: '',
                    });
                    window.alert("Mise à jour réussie");
                    afficheProvince();
                })
                .catch((error) => {
                    console.error("Erreur lors de la mise à jour :", error);
                    window.alert("Erreur lors de la mise à jour : " + error.message);
                });
        } else {

        axios
        .post(
          "http://localhost:100/province",
          {
            code_province: code,
            province: province,
          }
        )
        .then((res) => {
          setProvinces([...provinces, res.data]);
          alert("Ajout avec succès");
          afficheProvince();
          setCode("");
          setProvince("");
        })
        .catch((error) => {
          console.log(error);
          // Gérer l'erreur ici
        });
    
      }
      }

    const CustomActions = ({ row }) => {


        const handleDelete = (id) => {
            const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
            if (confirmDelete) {
              axios
                .delete(`http://localhost:100/province/${id}`)
                .then((response) => {
                    alert("Suppression reussi");
                    afficheProvince();
                  })
                .catch((error) => {
                  console.error("Erreur lors de la suppression :", error);
                  window.alert("Erreur lors de la suppression : " + error.message);
                });
            }
          };

          const handleEdit = (row) => {
            setEditingData(row);
            setIsEditing(true);
        };

        return (
            <div><button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
                <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
            </div>
        );
    };

    const columns = [
        {
            name: 'PROVINCE',
            selector: (row) => row.province,
            sortable: true,
        },
        {
            name: "CODE",
            selector: (row) => row.code_province,
            sortable: true,
        },
        {
            name: 'Actions',
            cell: row => <CustomActions row={row} />,
        },
    ];

    const afficheProvince = async () =>{
        url
        .get("/province/")
        .then((res)=> setRecords(res.data))
        .catch((err) => console.log(err));
    };
    useEffect(()=>{
        afficheProvince();
    },[]);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <Navbare />
                    <form onSubmit={handleSubmit}>
                        <div className="row mb-2 top">
                            <div className="col-sm-6">
                                <p className="m-0 parametr">AJOUT PROVINCE</p> <br></br>
                               { editingData ? (
                                <>
                               <div className='lab'>
                                    <label>CODE:</label>
                                    <input name='code_province' value={editingData.code_province} onChange={handleInput} className='joute' />
                                </div>
                                <div className='lab'>
                                    <label>PROVINCE :</label>
                                    <input className='jout' name='province' value={editingData.province} onChange={handleInput} />
                                </div>
                                </>
                                ):(
                                    <>
                                <div className='lab'>
                                <label>CODE:</label>
                                <input name='code' value={code} onChange={(e) => setCode(e.target.value)} className='joute' />
                            </div>
                            <div className='lab'>
                                <label>PROVINCE :</label>
                                <input className='jout' name='code' value={province} onChange={(e) => setProvince(e.target.value)} />
                            </div>
                            </>
                            )}
                                <div className='btn-parte'>
                                    <button className='btn btn-primary' onClick={ajouterProvince}>{isEditing ? "Modifier": "Ajouter"}</button>
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className='parametre'>
                                    <p>LISTE DES PROVINCE</p>
                                </div>
                                <div className='tabl'>
                                    <DataTable
                                        columns={columns}
                                        data={records}
                                        pagination
                                    // highlightOnHover
                                    />  </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            {/* <Footer/> */}
        </div >

    )
}
