import React, { useState, useEffect } from 'react'
import Navbare from '../navbar';
import '../../css/commune.css';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from 'axios';
import url from '../../url';

export const Categorie = () => {
    
    const [categorie, setCategorie]= useState('');
    const [records, setRecords]= useState([]);
    const [categories, setCategories]= useState('');
    const [editingData, setEditingData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
   

    const handleSubmit = (e) =>{
       e.preventDefault();
    };

    const handleInput = (e) => {
      const { name, value } = e.target;
      setEditingData({ ...editingData, [name]: value });
  };

    const ajoutCategorie = () =>{
      if (isEditing) {

        axios
            .put(`http://localhost:100/categorie/${editingData.id}`, {
                categorie: editingData.categorie,
            })
            .then((res) => {
                setIsEditing(false);
                setEditingData({
                    categorie: '',
                });
                window.alert("Mise à jour réussie");
                afficheCategorie();
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour :", error);
                window.alert("Erreur lors de la mise à jour : " + error.message);
            });
    } else {
        axios
        .post(
          "http://localhost:100/categorie",
          {
            categorie: categorie,
          }
        )
        .then((res) => {
          setCategories([...categories, res.data]);
          alert("Ajout avec succès");
          afficheCategorie();
          setCategorie("");
        })
        .catch((error) => {
          console.log(error);
          // Gérer l'erreur ici
        });
    }
      }

const CustomActions = ({ row }) => {



    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
        if (confirmDelete) {
          axios
            .delete(`http://localhost:100/categorie/${id}`)
            .then((response) => {
                alert("Suppression reussi");
                afficheCategorie();
              })
            .catch((error) => {
              console.error("Erreur lors de la suppression :", error);
              window.alert("Erreur lors de la suppression : " + error.message);
            });
        }
      };
  
    
      const handleEdit = (row) => {
        setEditingData(row);
        setIsEditing(true);
    };

    return (
        <div><button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
            <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
        </div>
    );
};

const columns = [
    {
        name: "Catégorie",
        selector: 'categorie',
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => <CustomActions row={row} />,
    },
];


const afficheCategorie = async () => {
  url
    .get("/categorie/")
    .then((res) => {
      setRecords(res.data)
    })
    .catch((err) => console.log(err));
};
useEffect(() => {
  afficheCategorie();
}, []);

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <Navbare />
                    <form onSubmit={handleSubmit}>
                    <div className="row mb-2 top">
                        <div className="col-sm-6">
                            <p className="m-0 parametr">AJOUT CATÉGORIE</p> <br></br>
                            {editingData ? (
                              <>
                            <div className='lab'>
                                <label>Catégorie :</label>
                                <input className='form-control form-contol-sm'name='categorie' type='text' value={editingData.categorie} onChange={handleInput} />
                            </div></>
                            ):(
                            <>
                              <div className='lab'>
                                <label>Catégorie :</label>
                                <input className='form-control form-contol-sm'name='categorie' type='text' value={categorie} onChange={(e)=>setCategorie(e.target.value)} />
                            </div>
                            </>
                            )
                          }
                            <div className='btn-parte'>
                                <button className='btn btn-primary' type='submit' onClick={ajoutCategorie}>{isEditing ? "Modifier" :"Ajouter"}</button>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='parametre'>
                                <p>LISTE DES CATÉGORIES</p>
                            </div>
                            <div className='tabl'>
                                <DataTable
                                    columns={columns}
                                    data={records}
                                    pagination
                                // highlightOnHover
                                /></div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            {/* <Footer/> */}
        </div>

    )
}
