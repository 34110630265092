import React, { useState, useEffect } from 'react'
import Navbare from '../navbar';
import '../../css/region.css';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import axios from 'axios';
import url from '../../url';

export const Region = () => {

const [records, setRecords] = useState([]);   
const [region, setRegion] = useState ('');
const [regions, setRegions] = useState ('');
const [editingData, setEditingData] = useState(null);
const [isEditing, setIsEditing] = useState(false);


const handleSubmit = (e) =>{
    e.preventDefault();
}

const handleInput = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
};

const CustomActions = ({ row }) => {

    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
        if (confirmDelete) {
          axios
            .delete(`http://localhost:100/region/${id}`)
            .then((response) => {
                alert("Suppression reussi");
                afficheRegion();
              })
            .catch((error) => {
              console.error("Erreur lors de la suppression :", error);
              window.alert("Erreur lors de la suppression : " + error.message);
            });
        }
      };
  
    
      const handleEdit = (row) => {
        setEditingData(row);
        setIsEditing(true);
    };

    return (
        <div><button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
            <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
        </div>
    );
};

const columns = [
    {
        name: 'PROVINCE',
        selector: (row) => row.provinces.province,
        sortable: true,
    },
    {
        name: "RÉGION",
        selector: (row) => row.region,
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => <CustomActions row={row} />,
    },
];

const afficheRegion = async () =>{
    url
    .get("/region/")
    .then((res)=> setRecords(res.data))
    .catch((err) => console.log(err));
};
useEffect(()=>{
    afficheRegion();
},[]);



const [selectedOption, setSelectedOption] = useState('');
const [options, setOptions] = useState([]);

const handleAdd = () => {
    if (isEditing) {

        axios
            .put(`http://localhost:100/region/${editingData.id}`, {
                id_province: editingData.id_province,
                region: editingData.region,
            })
            .then((res) => {
                setIsEditing(false);
                setEditingData({
                    id_province:"",
                    region: '',
                });
                window.alert("Mise à jour réussie");
                afficheRegion();
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour :", error);
                window.alert("Erreur lors de la mise à jour : " + error.message);
            });
    } else {

    axios
    .post(`http://localhost:100/region/`,
    {
        id_province: selectedOption,
        region: region,
    }
    )
    .then((res)=>{
        setRegions ([...regions, res.data]);
        alert("ajout avec succès");
        afficheRegion();
        setRegion("");
        setSelectedOption("");
    })
    }
};

useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:100/province");
            setOptions(response.data);
        } catch (error) {
            console.error("Erreur lors de la récupération des options : ", error);
        }
    };

    fetchData();
}, []);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <Navbare />
                    <form onSubmit={handleSubmit}>
                    <div className="row mb-2 top">
                        <div className="col-sm-6">
                            <p className="m-0 parametr">AJOUT RÉGION</p> <br></br>
                            { editingData ? (
                                <>
                                <div className='lab'>
                                <label>PROVINCE:</label>
                                <select value={editingData.id_province} onChange={handleInput} className='jou'name='id_province'>
                                {options.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.province}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='lab'>
                                <label>RÉGION :</label>
                                <input name='region' value={editingData.region} onChange={handleInput} className='jout' />
                            </div> </>
                            ):(
                                <>
                                 <div className='lab'>
                                <label>PROVINCE:</label>
                                <select value={selectedOption} onChange={handleSelectChange} className='jou'>
                                <option> </option>
                                {options.map((option) => (

                                  <option key={option.id} value={option.id}>
                                    {option.province}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='lab'>
                                <label>RÉGION :</label>
                                <input name='region' value={region} onChange={(e) => setRegion(e.target.value)} className='jout' />
                            </div>
                                </>
                            )}
                            <div className='btn-part'>
                                <button className='btn btn-primary' type='submit' onClick={handleAdd}>{isEditing ? "Modifier": "Ajouter"}</button>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='parametre'>
                                <p>LISTE DES RÉGIONS</p>
                            </div>
                            <div className='tabl'>
                                <DataTable
                                    columns={columns}
                                    data={records}
                                    pagination
                                // highlightOnHover
                                /></div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            {/* <Footer/> */}
        </div>

    )
}
