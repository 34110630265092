import { useState,useEffect } from 'react';
import "../csssite/location.css";
import 'bootstrap/dist/css/bootstrap.css';
import { Naves } from './nav';
import {Foot} from './foot'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';



export const Location = () => {

const [records, setRecords]=useState([])
    useEffect(() => {
        const fetchData = async () => {
          try {
            const response = await axios.get("http://localhost:100/location");
            setRecords(response.data);
          } catch (error) {
            console.error("Erreur lors de la récupération des données : ", error);
          }
        };
      
        fetchData();
      }, []);
      

    const columns = 4;
    const dataChunks = [];

    for (let i = 0; i < records.length; i += columns) {
        dataChunks.push(records.slice(i, i + columns));
    }

    const navigate = useNavigate();

  const handleLouerClick = (item) => {
      navigate('/detail', { state: { selectedItem: item } });
  };

    return (
        <>
            <Naves />
            <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <div className="container-fluid">
                    <div>
                        <div style={{ width: '100%', paddingLeft: '4%', paddingTop:'10%' }}>
                                <div >
                                    <div className='row dati'>
                                        {records.map((item, rowIndex) => (
                                            <div className='col-lg-3 col-md-6' key={rowIndex}>
                                                <div className='card custom-card text-white' style={{ width: '80%', height: '60%' }}>
                                                    <img src={item.image} className='piz' alt='Sary' style={{ objectFit: 'cover', width: '80%', height: '40%' }} />
                                                    <div className='nom'>
                                                        <p className='text-uppercase'>{item.title}</p>
                                                    </div>
                                                    <p className='text-body pe'>Prix : {item.price} ar </p>
                                                    <p className='text-body desi'> État : {item.etat}</p>
                                                    <button className='pani' onClick={() => handleLouerClick(item)}>Louer</button>
                                                    
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                        </div>
                    </div>
                </div>
            </div >
            <Foot/>
        </>
    );
}
