import React,{useState, useEffect} from 'react'
import Navbare from './navbar'
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import { MdDeleteForever } from 'react-icons/md';
import axios from 'axios';
import url from '../url';

export const Ajoutlocation = () => {

  const[ref, setRef]= useState('');
  const[nom, setNom]= useState('');
  const[etat, setEtat]= useState('');
  const[prix, setPrix]= useState('');
  const[date, setDate]= useState('');
  const[duree, setDuree]= useState('');
  const[condition, setCondition]= useState('');
  const[statut, setStatut]= useState('');
  const[image, setImage]= useState('');
  const [locations, setLocations]= useState('');
  const [records, setRecords] = useState([]);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const ajoutéLocation = () =>{
    const formdata = new FormData();
    formdata.append("image",image);
    formdata.append("ref",ref);
    formdata.append("etat",etat);
    formdata.append("date",date);
    formdata.append("duree",duree);
    formdata.append("title",nom);
    formdata.append("price",prix);
    formdata.append("condition",condition);
    formdata.append("statut",statut);
    axios
    .post(
      "http://localhost:100/location", formdata,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    )
    .then((res) => {
      setLocations([...locations, res.data]);
      handleClose();
      alert("Ajout avec succès");
      afficheLocation();
      setCondition("")
      setDate("");
      setDuree("");
      setEtat("");
      setImage("");
      setNom("");
      setPrix("");
      setStatut("");
      setRef("");
    })
    .catch((error) => {
      console.log(error);
      // Gérer l'erreur ici
    });

  }

const CustomActions = ({ row }) => {
  const [showmodal, setShowmodal] = useState(false);

  const handleClosemodal = () => setShowmodal(false);

  const handleEdit = () => {
   setShow(true);
  };

  const handleDelete = (id) => {
    const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
    if (confirmDelete) {
      // Assurez-vous que l'ID est une chaîne de caractères ou un nombre valide

      axios
        .delete("http://localhost:100/location/" + id)
        .then(() => {
          const updatedLocations = Array.isArray(locations) ? locations.filter((location) => location.id !== id) : [];
          setLocations(updatedLocations);

          window.alert("Suppression réussie");
          afficheLocation();
        })
        .catch((error) => {
          console.error("Erreur lors de la suppression :", error);
          window.alert("Erreur lors de la suppression : " + error.message);
        });
    }
  };

  return (
      <div>
      <button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>  
      <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
    </div>
  );
};

const columns = [
  {
    name: 'Nom',
    selector: (row) =>row.title,
    sortable: true,
  },
  {
    name: "Prix",
    selector: (row) => row.price,
    sortable: true,
  },
  {
    name: "Ref",
    selector: (row) => row.ref,
    sortable: true,
  },
  {
    name: 'Statut',
    selector: (row) => row.statut,
    sortable: true,
  },
  {
    name: 'Actions',
    cell: row => <CustomActions row={row} />,
  },
];

const afficheLocation = async () => {
  url
    .get("/location/")
    .then((res) => {
      setRecords(res.data)
    })
    .catch((err) => console.log(err));
};
useEffect(() => {
  afficheLocation();
}, []);



 const [show, setShow] = useState(false);

const handleClose = () => setShow(false);
const handleShow = () => setShow(true);
  return (
    <div>
    <div className="content-wrapper">
    <div className="content-header">
      <div className="container-fluid">
        <Navbare/>
        <div className="row mb-2 top">
          <div className="col-sm-4 liste">
            <h3 className="m-0">LISTE DES LOCATIONS</h3>
          </div>
          <div className="col-sm-3">
            <ol className="breadcrumb float-sm-right reche">
              <input
                type="text"
                name="recherche"
                // onChange={handlefilter}
                className="input form-control"
                placeholder="Recherche..."
              />
              <li className="breadcrumb-item active"></li>
            </ol>
          </div>
          <div className="col-sm-3">
          <button className='btn btn-primary' onClick={handleShow} type='button'>+ Ajouter</button>
          <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton className='header'>
          <Modal.Title className='text-primary'>AJOUT LOCATION</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className='container'>
                <Form.Group className=" row mb-3" >
                  <div className='col-sm-6'>
                    <input
                      name='ref'
                      value={ref}
                      onChange={(e)=>setRef(e.target.value)}
                      type="number"
                      placeholder='Ref'
                      className='form-control'
                      autoFocus
                    /></div>
                     <div className='col-sm-6'>
                    <input
                      name='nom'
                      value={nom}
                      onChange={(e)=>setNom(e.target.value)}
                      type="text"
                      placeholder='Nom'
                      className='form-control'
                      autoFocus
                    /></div>
                </Form.Group>
              </div>
              <div className='container'>
                <Form.Group className="row mb-3">
                  <div className='col-sm-6'>
                    <input type="text" name='etat' value={etat} onChange={(e)=>setEtat(e.target.value)} placeholder='État' className='form-control' />
                  </div>
                  <div className='col-sm-6'>
                    <input type="number" name='prix' value={prix} onChange={(e)=>setPrix(e.target.value)} placeholder='Prix' className='form-control' />
                  </div>
                </Form.Group>
          </div>
          <div className='container'>
                <Form.Group  className="row mb-3 " >
                  <div className='col-sm-6'> 
                    <input
                      name='date'
                      value={date}
                      onChange={(e)=>setDate(e.target.value)}
                      type="date"
                      placeholder='Date de mise en location'
                      autoFocus
                      className='form-control'
                    /></div>
                    <div className='col-sm-6'> 
                    <input
                      name='duree'
                      value={duree}
                      onChange={(e)=>setDuree(e.target.value)}
                      type="text"
                      placeholder='Durée max de location'
                      autoFocus
                      className='form-control'
                    /></div>
                </Form.Group>
              </div>
              <div className='container'>
                <Form.Group  className="row mb-3 " >
                  <div className='col-sm-6'> 
                    <input
                      name='statut'
                      value={statut}
                      onChange={(e)=>setStatut(e.target.value)}
                      type="text"
                      placeholder='Statut'
                      autoFocus
                      className='form-control'
                    /></div>
                    <div className='col-sm-6'> 
                       <input
                          name='image' 
                          type="file"
                          onChange={(e) => {
                            setImage(e.target.files[0]);
                          }}
                          placeholder='Image'
                          autoFocus
                          accept =".jpeg, .png, .gif, .svg, .jpg"
                          className='form-control'
                        />
                    </div>
                </Form.Group>
              </div>
              <div className='container'>
                <Form.Group  className="">
                  <div className=''>
                    <textarea
                      name='condition'
                      value={condition}
                      onChange={(e)=>setCondition(e.target.value)}
                      className='form-control'
                      type="text"
                      placeholder='Condition'
                      autoFocus
                    /></div>
                </Form.Group>
              </div>  
        </Modal.Body>
      
        <Modal.Footer className='footer'>
          <button type='submit' className='btn-primary' onClick={()=>ajoutéLocation()}>
            Publier
          </button>
        </Modal.Footer>
        </form>
      </Modal>
      
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
      <div className='data'>
      <DataTable
        columns={columns}
        data={records}
        pagination
      // highlightOnHover
      /></div>
    </div>
</div>
  )
}
