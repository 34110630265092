import React, {useState, useEffect} from 'react';
import '../css/listeUser.css';
import Navbare from './navbar';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { LiaEyeSolid } from 'react-icons/lia';
import { Modal } from 'react-bootstrap';
import url from '../url';

export const ListeUser = () => {

  const [records, setRecords] = useState([]);
  const [recordfilter, setrecordFilter] = useState([]);
  const handlefilter = (e) => {
    const new_data = recordfilter.filter((row) =>
      row.entite.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRecords(new_data);
  };

  const CustomActions = ({ row }) => {

    const [showModal, setShowModal] = useState(false);
    const [showmodal, setShowmodal] = useState(false);
  
    const handleCloseModal = () => setShowModal(false);
    const handleClosemodal = () => setShowmodal(false);
  
    const handleDelete = (id) => {
      const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
      if (confirmDelete) {
       url
          .delete(`/user/${id}`)
          .then((response) => {
              alert("Suppression reussi");
              afficheUser();
            })
          .catch((error) => {
            console.error("Erreur lors de la suppression :", error);
            window.alert("Erreur lors de la suppression : " + error.message);
          });
      }
    };
  
    const handleView = () => {
      setShowModal(true);
    };
  
  return (
    <div>
      <button type='button' className='icon view'><LiaEyeSolid onClick={() => handleView(row)} /></button>
      <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
    </div>
  );
};

const columns = [
  {
    name: 'Dénomination',
    selector:  (row)=> row.denomination,
    sortable: true,
    className:'custom-column-border'
  },
  {
    name: "Activité d'entité",
    selector: (row)=> row.activite_entite,
    sortable: true,
    className:'custom-column-border'
  },
  {
    name: 'Email',
    selector: (row)=>row.email,
    sortable: true,
  },
  {
    name: 'Téléphone',
    selector: (row)=>row.num_tel,
    sortable: true,
  },
  {
    name: 'Actions',
    cell: row => <CustomActions row={row} />,
  },
];

const afficheUser = async () => {
  url
    .get("/user/")
    .then((res) => {
      setRecords(res.data);
      setrecordFilter(res.data)
    })
    .catch((err) => console.log(err));
};
useEffect(() => {
  afficheUser();
}, []);


  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
          <Navbare/>
            <div className="row mb-2 top">
              <div className="col-sm-6">
                <h1 className="m-0 util ">LISTE DES UTILISATEURS</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    onChange={handlefilter}
                    className="input form-control rech"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='data'>
        <DataTable
          columns={columns}
          data={records}
          pagination
          // highlightOnHover
        /></div>
    </div>
  )
}
