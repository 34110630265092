import React, {useState, useEffect } from "react";
import dayjs from "dayjs";
import "bootstrap/dist/css/bootstrap.min.css";
import '../csssite/opportunite.css';
import axios from "axios";
import { Naves } from './nav';
import {Foot} from './foot';
import { useNavigate } from 'react-router-dom';

export const Opportunite = () => {

  const [records, setRecords] = useState([]);
  const navigate = useNavigate();

  const handleInscri = (item) => {
    navigate('/inscriptionOpp', { state: { selectedItem: item } });
};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/opportunite");
        setRecords(response.data);
        // setrecordFilter(response.data); 
      } catch (error) {
        console.error("Erreur lors de la récupération des données : ", error);
      }
    };

    fetchData(); 
  }, []);

  const formating = (date) => {
    return dayjs(date).format("DD, MMMM, YYYY");
  };

  // const [setsearch] = useState("");
  // const handleChange = (e) => {
  //   setsearch(e.target.name.toLowerCase());
  // };

  // const handleChang = (event) => {
  //   console.log(event.target.value);
  //   setSelected(event.target.value);
  // };

  return (
    <>
    <Naves/>
      {/* <p className="text-center filtre">
        <nav className="navbar navbar-expand-sm bg-light navbar-light">
          <div className="container-fluide">
            <button
              className="formation navbar-brand"
              name="Formation"
              onClick={handleChange}
            >
              Formation
            </button>
            <button
              onClick={handleChange}
              className="event navbar-brand"
              name="Evènement"
            >
              Evènement
            </button>
            <button className="tous navbar-brand" name="Tous">
              Tous
            </button>
          </div>
        </nav>
      </p> */}
      <div className="opportunitecontainer">
        {records.map((item) => (
          <div key={item.id_opportunite} className="card_item">
            <div className="card_inner">
              <div className="entete">
                <div className="date">
                  <p className=" text-gray-50">{item.choix}</p>
                </div>
                <div className="entete_droit">
                  <h4 className="nom_auteur text-uppercase">{item.organisateur}</h4>
                  <h6 className="categorie_offre">+261{item.contact}</h6>
                </div>
              </div>
              <p className="">
                <b>Titre:</b> {item.titre}
                <br />
                <b>Lieu:</b> {item.adresse}
                <br />
                <b>Durée: </b>
                {item.duree}
                <br />
                <b>Début:</b> {formating(item.date_debut)}
                <br />
                <b>Fin:</b> {formating(item.date_fin)}
                <br />
                <b>Tarifs:</b> {item.tarif} Ar
                <br />
                <b>Status:</b> {item.status}
              </p>
              <div>
                <button className="btn-primary" onClick={() => handleInscri(item)}>Voir les détails</button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <Foot/>
    </>
  );
}

