import React, { useState, useEffect } from 'react';
import Navbare from './navbar';
import '../css/ajoutopportunite.css';
import DataTable from 'react-data-table-component';
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { LiaEyeSolid } from 'react-icons/lia';
import Modal from "react-bootstrap/Modal";
import url from '../url';
import axios from 'axios';

export const Ajoutopportunite = () => {

  const [titre, setTitre] = useState('');
  const [select, setSelect] = useState('');
  const [contenu, setContenu] = useState('');
  const [debut, setDebut] = useState('');
  const [fin, setFin] = useState('');
  const [contact, setContact] = useState('');
  const [adresse, setAdresse] = useState('');
  const [duree, setDuree] = useState('');
  const [tarif, setTarif] = useState('');
  const [email, setEmail] = useState('');
  const [organisateur, setOrganisateur] = useState('');
  const [choix, setChoix] = useState('');
  const [opportunites, setOpportunites] = useState('');
  const [, setIsEditing] = useState(false);
  const [editingData, setEditingData] = useState(
    {
      titre: '',
      status: '',
      contenu: '',
      date_debut: '',
      date_fin: '',
      contact: '',
      adresse: '',
      duree: '',
      tarif: '',
      organisateur: '',
      choix: '',
      email:'',
    }
  );


  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleInput = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
  };

  const ModifOpportunite = () => {
    axios
      .put(`http://localhost:100/opportunite/${editingData.id}`, {
        choix: editingData.choix,
        date_debut: editingData.date_debut,
        date_fin: editingData.date_fin,
        titre: editingData.titre,
        contenu: editingData.contenu,
        contact: editingData.contact,
        duree: editingData.duree,
        tarif: editingData.tarif,
        organisateur: editingData.organisateur,
        adresse: editingData.adresse,
        status: editingData.status,
        email:editingData.email
      })
      .then((res) => {
        setIsEditing(false);
        // setEditingData({
        //   choix: '',
        //   date_debut: '',
        //   date_fin: '',
        //   titre: '',
        //   contenu: '',
        //   contact: '',
        //   duree: '',
        //   tarif: '',
        //   organisateur: '',
        //   adresse: '',
        //   status: ''
        // });
        setShowmodal(false);
        window.alert("Mise à jour réussie");
        afficheOpport();
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour :", error);
        window.alert("Erreur lors de la mise à jour : " + error.message);
      });
  }


  const AjoutOpportunité = () => {
    axios
      .post(
        "http://localhost:100/opportunite",
        {
          choix: select,
          date_debut: debut,
          date_fin: fin,
          titre: titre,
          contenu: contenu,
          contact: contact,
          duree: duree,
          tarif: tarif,
          organisateur: organisateur,
          adresse: adresse,
          status: choix,
          email: email
        }
      )

      .then((res) => {
        setOpportunites([...opportunites, res.data]);
        handleClose();
        alert("Ajout avec succès");
        afficheOpport();
        setAdresse("");
        setContact("");
        setContenu("");
        setDebut("");
        setDuree("");
        setFin("");
        setOrganisateur("");
        setTarif("");
        setTitre("");
        setEmail('');
      })
      .catch((error) => {
        console.log(error);
      });
  };


  const [showModal, setShowModal] = useState(false);
  const [showmodal, setShowmodal] = useState(false);
  const [modalData, setModalData] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleClosemodal = () => setShowmodal(false);


  const CustomActions = ({ row }) => {




    const handleEdit = (row) => {
      setEditingData(row);
      setIsEditing(true);
      setShowmodal(true);
    };


    const handleDelete = (id) => {
      const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
      if (confirmDelete) {
        axios
          .delete(`http://localhost:100/opportunite/${id}`)
          .then(() => {
            window.alert("Suppression réussie");
            afficheOpport();
          })
          .catch((error) => {
            console.error("Erreur lors de la suppression :", error);
            window.alert("Erreur lors de la suppression : " + error.message);
          });
      }
    };

    const handleView = (row) => {
      setModalData(row);
      setShowModal(true);
    };

    return (
      <div>
        <button type='button' className='icon view'><LiaEyeSolid onClick={() => handleView(row)} /></button>
        <Modal show={showModal} onHide={handleCloseModal} size='sm'>
          <Modal.Header className='header' closeButton />
          <Modal.Body className='text-center'>
            <p><p1 className='fiche'>Choix:</p1> {modalData.choix}</p>
            <p><p1 className='fiche'>Titre :</p1> {modalData.titre}</p>
            <p><p1 className='fiche'>Contenu :</p1> {modalData.contenu}</p>
            <p><p1 className='fiche'>Contact :</p1> {modalData.contact}</p>
            <p><p1 className='fiche'>Adresse:</p1> {modalData.adresse}</p>
            <p><p1 className='fiche'>Durée : </p1>{modalData.duree}</p>
            <p><p1 className='fiche'>Date de début : </p1>{modalData.date_debut}</p>
            <p><p1 className='fiche'>Date de fin : </p1>{modalData.date_fin}</p>
            <p><p1 className='fiche'>Organisateur : </p1>{modalData.organisateur}</p>
            <p><p1 className='fiche'>Status : </p1>{modalData.status}</p>
            <p><p1 className='fiche'>Tarif : </p1>{modalData.tarif}</p>
          </Modal.Body>
          <Modal.Footer className='footer'>
            <button onClick={handleCloseModal} className='btn-secondary fer'>Fermer</button>
          </Modal.Footer>
        </Modal>

        <button type='button' className='icon edit'>
          <AiTwotoneEdit onClick={() => handleEdit(row)} />
        </button>
        <Modal show={showmodal} onHide={handleClosemodal}>
          <Modal.Header closeButton className='header'>
            <Modal.Title>
              <div className='p1'>
                <p1> MODIFICATION OPPORTUNITÉ</p1>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='container form'>
              <form onSubmit={handleSubmit}>

                <div className='row'>
                  <div className='form-group row'>
                    <div className='col-sm-6'> <br></br>
                      <div className='inputbox'>

                        <input type='text' name='titre' className='form-control bg-light text-body' value={editingData.titre} onChange={handleInput} placeholder='Titre' />
                      </div>
                    </div>
                    <div className='col-sm-6'> <br></br>
                      <div className='select-container'>
                        <label htmlFor='choix types'><strong>choix types</strong></label>
                        <select htmlFor="choix types" id="continent" value={editingData.choix} onChange={handleInput} name='choix' className='form-control bg-light'>

                          <option value="Évènement">Évènement</option>
                          <option value="Formation">Formation</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> <br></br>
                <div className='inputbox'>
                  <input type='text' className='form-control bg-light text-body ' name='contenu' value={editingData.contenu} onChange={handleInput} placeholder='Contenu' />
                </div><br></br>
                <div className='row'>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <div className='select-container'>
                        <label htmlFor='debut'><strong>Date de début</strong></label>
                        <input htmlFor="debut" type='date' className='form-control bg-light text-body' name='date_debut' value={editingData.date_debut} onChange={handleInput} placeholder='Date de début' />
                      </div> </div>
                    <div className='col-sm-6 date'>
                      <div className='select-container'>
                        <label htmlFor='fin'><strong>Date de fin</strong></label>
                        <input htmlFor="fin" type='date' className='form-control bg-light tex-body' name='date_fin' value={editingData.date_fin} onChange={handleInput} placeholder='Date de fin' />
                      </div> </div>
                  </div>
                </div> <br></br>
                <div className='inputbox'>
                  <input type='number' className='form-control bg-light text-body' name='contact' value={editingData.contact} onChange={handleInput} placeholder='Contact' />
                </div> <br></br>
                <div className='inputbox'>
                  <input className='form-control bg-light text-body' name='adresse' value={editingData.adresse} onChange={handleInput} placeholder='Adresse' />
                </div> <br></br>
                <div className='row'>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <div className='inputbox'>
                        <input className='form-control bg-light text-body' name='duree' value={editingData.duree} onChange={handleInput} placeholder='Durée' />
                      </div> </div>
                    <div className='col-sm-6'>
                      <div className='inputbox'>
                        <input type='number' className='form-control bg-light text-body' name='tarif' value={editingData.tarif} onChange={handleInput} placeholder='Tarif' />
                      </div> </div>
                  </div>
                </div> <br></br>
                <div className='row'>
                  <div className='form-group row'>
                    <div className='col-sm-6'>
                      <div className='select-container'>
                        <input className='form-control bg-light text-body' name='organisateur' value={editingData.organisateur} onChange={handleInput} placeholder='Organisateur' />
                      </div> </div>
                    <div className='col-sm-6 date'>
                      <div className='select-container'>
                        <label htmlFor='status'><strong>Status</strong></label>
                        <select htmlFor="status" id="continent" value={editingData.status} onChange={handleInput} name='status' className='form-control bg-light'>
                          <option value="Gratuit">Gratuit</option>
                          <option value="Payant">Payant</option>
                        </select>
                      </div>

                    </div>
                  </div>
                </div> <br></br>
              </form>
            </div>
          </Modal.Body>
          <Modal.Footer className='footer'>
            <div className=''>
              <button type="submit" onClick={ModifOpportunite} className='btn-primary'>Modifier</button>
            </div>
          </Modal.Footer>
        </Modal>
        <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
      </div>
    );
  };

  const columns = [
    {
      name: 'Types',
      selector: (row) => row.choix,
      sortable: true,
    },
    {
      name: 'Titre',
      selector: (row) => row.titre,
      sortable: true,
    },
    {
      name: 'Durée',
      selector: (row) => row.duree,
      sortable: true,
    },
    {
      name: 'Contact',
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: 'Adresse',
      selector: (row) => row.adresse,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => <CustomActions row={row} />,
    },
  ];

  const afficheOpport = async () => {
    url
      .get("/opportunite/")
      .then((res) => {
        setRecords(res.data);
        setrecordFilter(res.data)
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    afficheOpport();
  }, []);

  const [records, setRecords] = useState([]);
  const [recordfilter, setrecordFilter] = useState([]);
  const handlefilter = (e) => {
    const new_data = recordfilter.filter((row) =>
      row.entite.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setRecords(new_data);
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <Navbare />
            <div className="row mb-2 top">
              <div className="col-sm-4 opp">
                <h3 className="m-0">LISTE DES OPPORTUNITÉ</h3>
              </div>
              <div className="col-sm-3">
                <ol className="breadcrumb float-sm-right reche">
                  <input
                    type="text"
                    name="recherche"
                    onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
              <div className="col-sm-3">
                <button className='btn btn-primary' onClick={handleShow} type='button'>+ Ajouter</button>
                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton className='header'>
                    <Modal.Title>
                      <div className='p1'>
                        <p1> AJOUT OPPORTUNITÉ</p1>
                      </div>
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className='container form'>
                      <form onSubmit={handleSubmit}>

                        <div className='row'>
                          <div className='form-group row'>
                            <div className='col-sm-6'> <br></br>
                              <div className='inputbox'>

                                <input type='text' name='titre' className='form-control bg-light text-body' value={titre} onChange={(e) => setTitre(e.target.value)} placeholder='Titre' />
                              </div> </div>
                            <div className='col-sm-6'> <br></br>
                              <div className='select-container'>
                                <label htmlFor='choix types'><strong>choix types</strong></label>
                                <select htmlFor="choix types" id="continent" value={select} onChange={(e) => setSelect(e.target.value)} name='select' className='form-control bg-light'>

                                  <option value="Évènement">Évènement</option>
                                  <option value="Formation">Formation</option>
                                </select>
                              </div>
                            </div>
                          </div>
                        </div> <br></br>
                        <div className='inputbox'>
                          <textarea type='text' className='form-control bg-light text-body ' name='contenu' value={contenu} onChange={(e) => setContenu(e.target.value)} placeholder='Contenu' />
                        </div><br></br>
                        <div className='row'>
                          <div className='form-group row'>
                            <div className='col-sm-6'>
                              <div className='select-container'>
                                <label htmlFor='debut'><strong>Date de début</strong></label>
                                <input htmlFor="debut" type='date' className='form-control bg-light text-body' name='debut' value={debut} onChange={(e) => setDebut(e.target.value)} placeholder='Date de début' />
                              </div> </div>
                            <div className='col-sm-6 date'>
                              <div className='select-container'>
                                <label htmlFor='fin'><strong>Date de fin</strong></label>
                                <input htmlFor="fin" type='date' className='form-control bg-light tex-body' name='fin' value={fin} onChange={(e) => setFin(e.target.value)} placeholder='Date de fin' />
                              </div> </div>
                          </div>
                        </div> <br></br>
                        <div className='inputbox'>
                          <input type='text' className='form-control bg-light text-body' name='contact' value={contact} onChange={(e) => setContact(e.target.value)} placeholder='Contact' />
                        </div> <br></br>
                        <div className='row'>
                          <div className='col-sm-6 inputbox'>
                            <input className='form-control bg-light text-body' name='adresse' value={adresse} onChange={(e) => setAdresse(e.target.value)} placeholder='Adresse' />
                          </div> <br></br>
                          <div className='col-sm-6 inputbox'>
                            <input className='form-control bg-light text-body' name='email' value={email} onChange={(e) => setEmail(e.target.value)} placeholder='Email' />
                          </div>
                        </div>
                        <div className='row'>
                          <div className='form-group row'>
                            <div className='col-sm-6'>
                              <div className='inputbox'>
                                <input className='form-control bg-light text-body' name='duree' value={duree} onChange={(e) => setDuree(e.target.value)} placeholder='Durée' />
                              </div> </div>
                            <div className='col-sm-6'>
                              <div className='inputbox'>
                                <input type='text' className='form-control bg-light text-body' name='tarif' value={tarif} onChange={(e) => setTarif(e.target.value)} placeholder='Tarif' />
                              </div> </div>
                          </div>
                        </div> <br></br>
                        <div className='row'>
                          <div className='form-group row'>
                            <div className='col-sm-6'>
                              <div className='select-container'>
                                <input className='form-control bg-light text-body' name='organisateur' value={organisateur} onChange={(e) => setOrganisateur(e.target.value)} placeholder='Organisateur' />
                              </div> </div>
                            <div className='col-sm-6 date'>
                              <div className='select-container'>
                                <label htmlFor='status'><strong>Status</strong></label>
                                <select htmlFor="status" id="continent" value={choix} onChange={(e) => setChoix(e.target.value)} name='choix' className='form-control bg-light'>
                                  <option value="Gratuit">Gratuit</option>
                                  <option value="Payant">Payant</option>
                                </select>
                              </div>

                            </div>
                          </div>
                        </div> <br></br>
                      </form>
                    </div>
                  </Modal.Body>
                  <Modal.Footer className='footer'>
                    <div className=''>
                      <button type="submit" onClick={AjoutOpportunité} className='btn-primary'> Ajouter</button>
                    </div>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='data'>
          <DataTable
            columns={columns}
            data={records}
            pagination
          // highlightOnHover
          /></div>
      </div>
    </div>
  )
};