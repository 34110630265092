import React, {useState, useEffect} from 'react';
import Navbare from './navbar';
import '../css/message.css';
import "bootstrap/dist/css/bootstrap.css";
import { LiaEyeSolid } from 'react-icons/lia';
import DataTable from 'react-data-table-component';
import { Modal } from 'react-bootstrap';
import url from '../url';

export const Message = () => {

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [modalData, setModalData] = useState(false);
  const [records, setRecords] = useState([]);

    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        const allRowIds = records.map((row) => row.id);
        setSelectedRows(allRowIds);
      }
      setSelectAll(!selectAll);
    };

    const handleCheckboxChange = (rowId) => {
      if (selectedRows.includes(rowId)) {
        setSelectedRows(selectedRows.filter((id) => id !== rowId));
      } else {
        setSelectedRows([...selectedRows, rowId]);
      }
    };

const CustomActions = ({ row }) => {

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);

    const handleView = row => {
      setShowModal(true);
       }

      return (
        <div>
          <button type='button' className='icon view'><LiaEyeSolid onClick={() => handleView(row)} /></button>
      
          <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header className='header' closeButton />
          <Modal.Body>
            <div className='text-center'>
              <img src={modalData.photo} alt='profil' style={{ width: '30%' }}></img>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <p><p1 className='fiche'>Nom:</p1> {modalData.nom}</p>
                <p><p1 className='fiche'>Date de naissance :</p1> {modalData.date_naissance}</p>
                <p><p1 className='fiche'>Lieu de naissance :</p1> {modalData.lieu_naissance}</p>
                <p><p1 className='fiche'>Contact :</p1> {modalData.contact}</p>
                <p><p1 className='fiche'>Sexe:</p1> {modalData.sexe}</p>
                <p><p1 className='fiche'>Type d'exploitation : </p1>{modalData.type_exploit}</p>
              </div>
              <div className='col-sm-6'>
                <p><p1 className='fiche'>Province : </p1>{modalData.province}</p>
                <p><p1 className='fiche'>Région : </p1>{modalData.region}</p>
                <p><p1 className='fiche'>District : </p1>{modalData.district}</p>
                <p><p1 className='fiche'>Commune : </p1>{modalData.commune}</p>
                <p><p1 className='fiche'>Fokontany : </p1>{modalData.id_fokotany}</p>
                <p><p1 className='fiche'>Cooperative: </p1>{modalData.id_cooperative}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='footer'>
            <button onClick={handleCloseModal} className=' btn-secondary'>Fermer</button>
          </Modal.Footer>
        </Modal>
        </div>
      );
  };

  const columns = [
    {
      name: (
        <input
          type='checkbox'
          checked={selectAll}
          onChange={handleSelectAll}
        />
      ), 
  cell: (row) => (  
     <input
          type='checkbox'
          checked={selectedRows.includes(row.id)}
          onChange={() => handleCheckboxChange(row.id)}
        />
      ),
    },
    {
      name: 'Noms',
      selector: (row) => row.nom,
      sortable: true,
    },
    {
      name: 'Région',
      selector: (row) => row.region,
      sortable: true,
    },
    {
      name: 'Commune',
      selector: (row) => row.commune,
      sortable: true,
    },
    {
      name: 'Tel',
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: 'CIN',
      selector: (row) => row.numero_karatra,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => <CustomActions row={row} />,
    },
  ];

  const afficheAgricult = async () =>{
    url
    .get("/agriculteur/")
    .then((res)=>{
    setRecords(res.data);
   })
    .catch((err) => console.log(err));
};
useEffect(()=>{
    afficheAgricult();
},[]);

  return (
    <div>
       <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <Navbare/>
            <div className="row mb-2 top">
              <div className="col-sm-6 uti">
                <h3 className="m-0">LISTE DES AGRICULTEURS</h3>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    // onChange={handlefilter}
                    className="input form-control rech"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </div>
          <div className='data'>
          <DataTable
            columns={columns}
            data={records}
            pagination
          // highlightOnHover
          /></div>
      <div className='row'>
        <div className='col-sm-6'>
          <label>Message</label>
          <textarea/>
        </div>
        <div className='col-sm-2'>
            <button className='envoyer'>Envoyer</button>
        </div>
      </div>   
    </div>
  )
}

