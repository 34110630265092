import React from 'react';
import '../csssite/accueil.css';
import { Naves } from './nav';
import {Foot} from './foot';

export const Accueil = () => {

  return (
    <div className='app'>
      <div>
        <Naves />
      </div>
      <div className='tonga'>
         <h1 className='eto'>TONGASOA</h1>
        </div>
      <div className='row'>
        <div className='col-sm-6'>
        <div className='pp'>
        <img src="./images/log.jpg" alt='responsive' className='img-fluid logos'></img> 
            <p>Découvrer notre produit et notre matériel<br></br>
              disponible ainsi que les formations<br></br>
              et événements disponible<br></br>
              chez nous
            </p>
          </div>
          <div className='bou'>
            <button className='btn-ton'>Découvrir les produits</button>
          </div>
        </div>
        <div className='col-sm-6'>
          <img src="./images/mat.jpg" alt='responsive' className='img-fluid mat'></img>
        </div>
      </div>
      <div className='foote'>
        <Foot/>
      </div>
    </div>
  )
}
