import React from 'react';
import '../csssite/panie.css';
import { Naves } from './nav';
import { Foot } from './foot';
import { useLocation } from 'react-router-dom';

function Panie() {
  const location = useLocation();
  const { selectedItem } = location.state || {};

  if (!selectedItem) {
    return <div>No item selected.</div>;
  }
  return (
    <div>
      <div>
        <Naves />
      </div>
      <div className='container co'>
        <div className='row'>
          <div className="col-sm-4">
            <img src={selectedItem.image} alt={selectedItem.title} className='img-fluid im' />
          </div>
          <div className="col-sm-6">
            <h3>Fiche matériel</h3>
            <p><p1 className='fiche'>Ref :</p1> {selectedItem.ref}</p>
            <p><p1 className='fiche'>Nom :</p1> {selectedItem.title}</p>
            <p><p1 className='fiche'>État :</p1> {selectedItem.etat}</p>
            <p><p1 className='fiche'>Prix :</p1> {selectedItem.price} ar</p>
            <p><p1 className='fiche'>Date de mise en location:</p1> {selectedItem.date}</p>
            <p><p1 className='fiche'>Durée maximum de location : </p1>{selectedItem.duree}</p>
            <p><p1 className='fiche'>Conditions :</p1> {selectedItem.condition}</p>
            <p className='fiche'>Statut : <p1 className='stat'>{selectedItem.statut}</p1></p>
            <div className='form-group carde'>
              <h3 className='frm'>Formulaire de location</h3>
              <div>
                <label>Date de location  </label>
                <input type='date' placeholder='dd/mm/yyyy' className='inpt'/>
              </div>
              <div>
                <label>Date de retour </label>
                <input type='date' placeholder='dd/mm/yyyy' className='inp'/>
              </div>
              <div>
                  <button className='btn btn-lou'>Louer le matériel</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Foot />
      </div>
    </div>
  );
}

export default Panie;
