import React, { useState, useEffect } from 'react'
import Navbare from '../navbar';
import '../../css/commune.css';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import url from '../../url';


export const Commune = () => {

const [commune, setCommune] = useState('');   
const [communes, setCommunes] = useState(''); 
const [records, setRecords] = useState([]);  
const [editingData, setEditingData] = useState(null);
const [isEditing, setIsEditing] = useState(false);

const handleInput = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
};

const handleSubmit = (e) =>{
    e.preventDefault();
}


const handleAdd = () => {
    if (isEditing) {

        axios
            .put(`http://localhost:100/commune/${editingData.id}`, {
                id_district: editingData.id_district,
                commune: editingData.commune,
            })
            .then((res) => {
                setIsEditing(false);
                setEditingData({
                    id_district:"",
                    commune: '',
                });
                window.alert("Mise à jour réussie");
                afficheCommune();
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour :", error);
                window.alert("Erreur lors de la mise à jour : " + error.message);
            });
    } else {
    axios
    .post(`http://localhost:100/commune/`,
    {
        id_district: selectedOption,
        commune: commune,
    }
    )
    .then((res)=>{
        setCommunes ([...communes, res.data]);
        alert("ajout avec succès");
        afficheCommune();
        setCommune("");
        setSelectedOption("");
    })
    }
};

const CustomActions = ({ row }) => {

    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
        if (confirmDelete) {
          axios
            .delete(`http://localhost:100/commune/${id}`)
            .then((response) => {
                alert("Suppression reussi");
                afficheCommune();
              })
            .catch((error) => {
              console.error("Erreur lors de la suppression :", error);
              window.alert("Erreur lors de la suppression : " + error.message);
            });
        }
      };
  
      const handleEdit = (row) => {
        setEditingData(row);
        setIsEditing(true);
    };

    return (
        <div><button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
            <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>
        </div>
    );
};

const columns = [
    {
        name: 'DISTRICT',
        selector: (row) => row.districts.district,
        sortable: true,
    },
    {
        name: "Commune",
        selector: (row) => row.commune,
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => <CustomActions row={row} />,
    },
];

const afficheCommune = async () =>{
    url
    .get("/commune/")
    .then((res)=> setRecords(res.data))
    .catch((err) => console.log(err));
};
useEffect(()=>{
    afficheCommune();
},[]);

const [selectedOption, setSelectedOption] = useState('');
const[districts, setDistricts]= useState([]);


useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:100/district");
            setDistricts(response.data); 
        } catch (error) {
            console.error("Erreur lors de la récupération des options : ", error);
        }
    };

    fetchData();
}, []);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <Navbare />
                    <form onSubmit={handleSubmit}>
                    <div className="row mb-2 top">
                        <div className="col-sm-6">
                            <p className="m-0 parametr">AJOUT COMMUNE</p> <br></br>
                           {editingData ? (
                            <> <div className='lab'>
                                <label>DISTRICT:</label>
                                <select value={editingData.id_district} onChange={handleInput} className='jous' name='id_district'>
                                {districts.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.district}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='lab'>
                                <label>COMMUNE :</label>
                                <input className='jout' name='commune' value={editingData.commune} onChange={handleInput}  />
                            </div></>
                            ):(
                            <>
                            <div className='lab'>
                                <label>DISTRICT:</label>
                                <select value={selectedOption} onChange={handleSelectChange} className='jous'>
                                    <option> </option>
                                {districts.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.district}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='lab'>
                                <label>COMMUNE :</label>
                                <input className='jout' name='commune' value={commune} onChange={(e)=>setCommune(e.target.value)}  />
                            </div>
                            </>
                            )}
                            <div className='btn-parte'>
                                <button className='btn btn-primary' type='submit' onClick={handleAdd}>{isEditing ?"Modifier":"Ajouter"}</button>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='parametre'>
                                <p>LISTE DES COMMUNE</p>
                            </div>
                            <div className='tabl'>
                                <DataTable
                                    columns={columns}
                                    data={records}
                                    pagination
                                // highlightOnHover
                                /></div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            {/* <Footer/> */}
        </div>

    )
}
