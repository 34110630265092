import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import '../css/profilAgri.css';
import Navbare from './navbar';
import DataTable from 'react-data-table-component';
import { AiTwotoneEdit } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";
import { MdDeleteForever } from 'react-icons/md'
import { LiaEyeSolid } from 'react-icons/lia';
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import axios from 'axios';
import url  from '../url';


const ProfilAgri = () => {

  const [nom, setNom] = useState('');
  const [dateN, setDateN] = useState('');
  const [karatra, setKaratra] = useState('');
  const [lieuN, setLieuN] = useState('');
  const [type, setType] = useState('');
  const [tel, setTel] = useState('');
  const [records, setRecords] = useState([]);
  const [valeurRadio, setValeurRadio] = useState('');
  const [agriculteurs, setAgriculteurs] = useState([]);
  const [photo, setPhoto]= useState('');

  const [province, setProvince] = useState('');
  const [region, setRegion] = useState('');
  const [district, setDistrict] = useState('');
  const [commune, setCommune] = useState('');
  const [selectedFokotanyId, setSelectedFokotanyId] = useState('');
  const [selectedFokotany, setSelectedFokotany] = useState('');
  const [selectedCooperative, setSelectedCooperative] = useState('');
  const [provinces, setProvinces] = useState([]);
  const [regions, setRegions] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [communes, setCommunes] = useState([]);
  const [fokotanies, setFokotanies] = useState([]);
  const [cooperatives, setCooperatives] = useState([]);




  const handleRadioChange = (e) => {
    setValeurRadio(e.target.value);
  };


  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const ajoutéAgriculteur = () => {
    const formdata = new FormData();
    formdata.append("nom", nom);
    formdata.append("date_naissance", dateN);
    formdata.append("lieu_naissance", lieuN);
    formdata.append("numero_karatra", karatra);
    formdata.append("type_exploit", type);
    formdata.append("contact", tel);
    formdata.append("photo", photo);
    formdata.append("sexe", valeurRadio);
    formdata.append("province", province);
    formdata.append("region", region);
    formdata.append("district", district);
    formdata.append("commune", commune);
    formdata.append("id_fokotany", selectedFokotanyId);
    formdata.append("id_cooperative", selectedCooperative);

    axios
      .post(
        "http://localhost:100/agriculteur", formdata,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        setAgriculteurs([...agriculteurs, res.data]);
        handleClose();
        alert("Ajout avec succès");
        afficheAgricult();
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const CustomActions = ({ row }) => {
    const [showModal, setShowModal] = useState(false);
    const [showmodal, setShowmodal] = useState(false);
    const [modalData, setModalData] = useState(false);

    const handleCloseModal = () => setShowModal(false);
    const handleClosemodal = () => setShowmodal(false);

    const handleEdit = () => {
      setShow(true);
    };

    const handleDelete = (id) => {
      const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
      if (confirmDelete) {
        axios
          .delete(`http://localhost:100/agriculteur/${id}`)
          .then(() => {
            window.alert("Suppression réussie");
            afficheAgricult();
          })
          .catch((error) => {
            console.error("Erreur lors de la suppression :", error);
            window.alert("Erreur lors de la suppression : " + error.message);
          });
      }
    };


    const handleView = () => {
      setModalData(row);
      setShowModal(true);
    };

    return (
      <div>
        <button type='button' className='icon view'><LiaEyeSolid onClick={() => handleView(row)} /></button>
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header className='header' closeButton />
          <Modal.Body>
            <div className='text-center'>
              <img src={modalData.photo} alt='profil' style={{ width: '30%' }}></img>
            </div>
            <div className='row'>
              <div className='col-sm-6'>
                <p><p1 className='fiche'>Nom:</p1> {modalData.nom}</p>
                <p><p1 className='fiche'>Date de naissance :</p1> {modalData.date_naissance}</p>
                <p><p1 className='fiche'>Lieu de naissance :</p1> {modalData.lieu_naissance}</p>
                <p><p1 className='fiche'>Contact :</p1> {modalData.contact}</p>
                <p><p1 className='fiche'>Sexe:</p1> {modalData.sexe}</p>
                <p><p1 className='fiche'>Type d'exploitation : </p1>{modalData.type_exploit}</p>
              </div>
              <div className='col-sm-6'>
                <p><p1 className='fiche'>Province : </p1>{modalData.province}</p>
                <p><p1 className='fiche'>Région : </p1>{modalData.region}</p>
                <p><p1 className='fiche'>District : </p1>{modalData.district}</p>
                <p><p1 className='fiche'>Commune : </p1>{modalData.commune}</p>
                <p><p1 className='fiche'>Fokontany : </p1>{modalData.id_fokotany}</p>
                <p><p1 className='fiche'>Cooperative: </p1>{modalData.id_cooperative}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className='footer'>
            <button onClick={handleCloseModal} className=' btn-secondary'>Fermer</button>
          </Modal.Footer>
        </Modal>

        <button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>

        <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>

        <Modal show={showmodal} onHide={handleClosemodal} centered>
          <Modal.Header className='header' closeButton />
          <Modal.Body className='text-center '>
            <MdDeleteForever className='del' /> Voulez-vous vraiment supprimer cet élément?
          </Modal.Body>
          <Modal.Footer className='footer'>
            <button onClick={handleClosemodal} className='btn btn-secondary fe'>Annuler</button>
            <button onClick={handleClosemodal} className='btn btn-danger bu'>supprimer</button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  };

  const columns = [
    {
      name: 'Noms',
      selector: (row) => row.nom,
      sortable: true,
    },
    {
      name: 'Région',
      selector: (row) => row.region,
      sortable: true,
    },
    {
      name: 'Commune',
      selector: (row) => row.commune,
      sortable: true,
    },
    {
      name: 'Tel',
      selector: (row) => row.contact,
      sortable: true,
    },
    {
      name: 'CIN',
      selector: (row) => row.numero_karatra,
      sortable: true,
    },
    {
      name: 'Actions',
      cell: row => <CustomActions row={row} />,
    },
  ];

  const afficheAgricult = async () =>{
    url
    .get("/agriculteur/")
    .then((res)=>{
    setRecords(res.data);
   })
    .catch((err) => console.log(err));
};
useEffect(()=>{
    afficheAgricult();
},[]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);



  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/fokotany");
        setFokotanies(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des options : ", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/commune");
        setCommunes(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des options : ", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/district");
        setDistricts(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des options : ", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/region");
        setRegions(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des options : ", error);
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/cooperative");
        setCooperatives(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des options : ", error);
      }
    };

    fetchData();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedCooperative(event.target.value);
  }

  const handleSelectFoko = (event) => {

    const selectedId = event.target.value;
    setSelectedFokotanyId(selectedId);

    const selectedFokotany = fokotanies.find((fokotany) => fokotany.id === parseInt(selectedId));
    if (selectedFokotany) {
      setCommune(selectedFokotany.communes.commune);

    const commune = communes.find((commune) => commune.id === selectedFokotany.communes.id);
      if (commune) {
        setDistrict(commune.districts.district);

    const district = districts.find((district) => district.id === commune.districts.id);
      if(district){
        setRegion(district.regions.region);
      } else {
        setRegion("");
      }

      const region = regions.find((region) => region.id === district.regions.id);
      if(region){
        setProvince(region.provinces.province);
      } else {
        setProvince("");
      }  

      } else {
        setDistrict('');
      }
    } else {
      setCommune(''); 
      setDistrict("");
      setRegion("");
      setProvince("");
    }
  };

  // const [uploadedFiles, setUploadedFiles] = useState([]);

  // const handleFileUpload = useCallback((files) => {
  //   setUploadedFiles(files);
  // }, [setUploadedFiles]);
  
  // const onDrop = useCallback(
  //   (acceptedFiles) => {
  //     handleFileUpload(acceptedFiles);
  //   },
  //   [handleFileUpload]
  // );

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({
  //   onDrop,
  //   accept: 'image/*',
  // });

  return (
    <div>
      <div className="content-wrapper">
        <div className="content-header">
          <div className="container-fluid">
            <Navbare />
            <div className="row mb-2 top">
              <div className="col-sm-4 liste">
                <h3 className="m-0">LISTE DES AGRICULTEURS</h3>
              </div>
              <div className="col-sm-3 pad">
                <ol className="breadcrumb float-sm-right">
                  <input
                    type="text"
                    name="recherche"
                    // onChange={handlefilter}
                    className="input form-control"
                    placeholder="Recherche..."
                  />
                  <li className="breadcrumb-item active"></li>
                </ol>
              </div>
              <div className="col-sm-3">
                <button className='btn btn-primary' onClick={handleShow} type='button'>+ Ajouter</button>
                <Modal show={show} onHide={handleClose}>
                  <form onSubmit={handleSubmit}>
                    <Modal.Header className='header' closeButton>
                      <Modal.Title className='text-center'>AJOUT AGRICULTEUR</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className='container'>
                      <div>
                            <input type="file" placeholder='Image' name='image' className='form-control' onChange={(e) => {
                              setPhoto(e.target.files[0]);
                            }} />
                          </div>
                        <br></br>

                        <div className='row'>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Nom et prénom</label>
                                <input
                                  type="text"
                                  name='nom' value={nom}
                                  onChange={(e) => setNom(e.target.value)}
                                  autoFocus
                                  className='form-control'
                                /></div>
                            </Form.Group>
                          </div>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Date de naissance</label>
                                <input type="text" className='form-control'
                                  name='dateN' value={dateN}
                                  onChange={(e) => setDateN(e.target.value)}
                                />
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Lieu de naissance :</label>
                                <input
                                  type="text"
                                 className='form-control'
                                  name='lieuN' value={lieuN}
                                  onChange={(e) => setLieuN(e.target.value)}
                                  autoFocus
                                /></div>
                            </Form.Group>
                          </div>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>N° CIN</label>
                                <input
                                  type="text"
                                  className='form-control'
                                  name='karatra' value={karatra}
                                  onChange={(e) => setKaratra(e.target.value)}
                                  autoFocus
                                /></div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Type d'exploitation</label>
                                <input
                                  type="text"
                                  className='form-control'
                                  name='type' value={type}
                                  onChange={(e) => setType(e.target.value)}
                                  autoFocus
                                /></div>
                            </Form.Group>
                          </div>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Contact</label>
                                <input
                                  type="text"
                                  className='form-control'
                                  name='tel' value={tel}
                                  onChange={(e) => setTel(e.target.value)}
                                  autoFocus
                                /></div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <Form.Group controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Fokotany</label>
                                <select value={selectedFokotanyId} onChange={handleSelectFoko} className='form-control'>
                                  <option> </option>
                                  {fokotanies.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.fokotany}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Form.Group>
                          </div>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Appartenance</label>
                                <select value={selectedCooperative} onChange={handleSelectChange} className='form-control'>
                                  <option> </option>
                                  {cooperatives.map((option) => (
                                    <option key={option.id} value={option.id}>
                                      {option.cooperative}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Province</label>
                              <input type="text" value={province} readOnly className='form-control'
                              onChange={(e)=>setProvince(e.target.value)} name='province'/>
                              </div>
                            </Form.Group>
                          </div>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Région</label>
                              <input type="text" value={region} readOnly className='form-control'
                              onChange={(e)=>setRegion(e.target.value)} name='region'/>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className='container'>
                        <div className='row'>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div >
                                <label>District</label>
                              <input type="text" value={district} readOnly className='form-control'
                              onChange={(e)=>setDistrict(e.target.value)} name='district'/>
                              </div>
                            </Form.Group>
                          </div>
                          <div className='col-sm-6'>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                              <div>
                                <label>Commune</label>
                                <input type="text" value={commune} readOnly className='form-control'
                                 onChange={(e)=>setCommune(e.target.value)} name='commune'/>
                              </div>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="Radio1"
                              value="Homme"
                              onChange={handleRadioChange}
                              checked={valeurRadio === 'Homme'}
                            />
                            <label className="form-check-label" htmlFor="Radio1">
                              Homme
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="inlineRadioOptions"
                              id="inlineRadio2"
                              value="Femme"
                              onChange={handleRadioChange}
                              checked={valeurRadio === 'Femme'}
                            />
                            <label className="form-check-label" htmlFor="inlineRadio2">
                              Femme
                            </label>
                          </div>
                        </Form.Group>
                      </div>
                    </Modal.Body>
                    <Modal.Footer className='footer'>
                      <button className='btn-primary' onClick={()=>{ajoutéAgriculteur();}}>
                        Enregistrer
                      </button>
                    </Modal.Footer>
                  </form>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className='data'>
          <DataTable
            className='table'
            columns={columns}
            data={records}
            pagination
          // highlightOnHover
          /></div>
      </div>
    </div>
  );
};

export default ProfilAgri;
