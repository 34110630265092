import { React, useEffect, useState } from 'react';
import '../csssite/inscriptionO.css';
import { Naves } from './nav';
import { Foot } from './foot';
import { useLocation } from 'react-router-dom';
import axios from "axios";
import { useNavigate } from 'react-router-dom';


export const InscriptionO = () => {

    const [selectedOption, setSelectedOption] = useState('');
    const [options, setOptions] = useState([]);
    const navigate = useNavigate();
    const [nom, setNom] = useState('');
    const [prenom, setPrenom] = useState('');
    const [age, setAge] = useState('');
    const [adresse, setAdresse] = useState('');
    const [email, setEmail] = useState('');
    const [contact, setContact] = useState('');
    const [code_postal, setCode_postal] = useState('');
    const [province, setProvince] = useState('');
    const [inscriptions, setInscriptions] = useState('');

    const handleSubmit = (e)=>{
        e.preventDefault();
    }
    
    const ajoutInscrits = () => {
        axios
          .post(
            "http://localhost:100/inscription",
            {
             nom:nom,
             prenom:prenom,
             age:age,
             adresse:adresse,
             contact:contact,
             email:email,
             code_postal:code_postal,
             id_province:selectedOption
            }
          )
          .then((res) => {
            setInscriptions([...inscriptions, res.data]);
            alert("Veuillez verifier votre email s'il vous plait");
            setNom('');
            setPrenom('');
            setAdresse('');
            setContact('');
            setEmail('');
            setCode_postal('');
            setProvince('');
            setAge('');
          })
          .catch((error) => {
            console.log(error);
          });
    
      }

    const handleRetour = (item) => {
        navigate('/opportunite', { state: { selectedItem: item } });
    };

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
      }

      useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://localhost:100/province");
                setOptions(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des options : ", error);
            }
        };
    
        fetchData();
    }, []);
    
    const location = useLocation();
    const { selectedItem } = location.state || {};

    if (!selectedItem) {
        return <div>Bonjour</div>;
    }

    return (
        <div>
            <div>
                <Naves />
            </div>
            <div className='container cot'>
                <div className='row'>
                    <div className="col-sm-6">
                        <h1 className='text-primary'>{selectedItem.organisateur}</h1>
                        <h4> {selectedItem.choix} : <b className='text-uppercase text-success'>{selectedItem.titre}</b></h4>
                        <div className='row'>
                            <div className='col-sm-4'>
                            <b className='text-decoration-underline'>Lieu</b>
                            : {selectedItem.adresse}
                            <br />
                            </div>
                            <div className='col-sm-4'>
                            <b className='text-decoration-underline'>Contact</b>
                            : {selectedItem.contact}
                            <br />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4'>
                            <b className='text-decoration-underline'>Status</b>
                            : {selectedItem.status}
                            </div>
                            <div className='col-sm-4'>
                            <b className='text-decoration-underline'>Tarifs</b>
                            : {selectedItem.tarif} Ar
                            <br />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-4'>
                            <b className='text-decoration-underline'>Début</b> 
                            : {selectedItem.date_debut}
                            <br />
                            </div>
                            <div className='col-sm-4'>
                            <b className='text-decoration-underline'>Fin</b> 
                            : {selectedItem.date_fin}
                            <br />
                            </div>
                        </div>
                        <p><b className='text-decoration-underline'>E-mail</b> 
                            : {selectedItem.email}
                            <br /></p>
                        <br></br>
                        <p> {selectedItem.contenu}</p>
                    </div>
                    <div className="col-sm-6 carde_in">
                        <form onSubmit={handleSubmit}>
                            <h2 className='text-center text-primary formulai'>Formulaire d'inscription</h2>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <label> Nom :</label>
                                    <input type='text' className='form-control' 
                                    name='nom' value={nom} onChange={(e)=>setNom(e.target.value)}/>
                                </div>
                                <div className='col-sm-6'>
                                    <label>Prénom : </label>
                                    <input type='text' className='form-control'
                                    name='prenom' value={prenom} onChange={(e)=>setPrenom(e.target.value)} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <label> Age :</label>
                                    <input type='text' className='form-control'
                                    name='age' value={age} onChange={(e)=>setAge(e.target.value)} />
                                </div>
                                <div className='col-sm-6'>
                                    <label>Adresse : </label>
                                    <input type='text' className='form-control' 
                                    name='adresse' value={adresse} onChange={(e)=>setAdresse(e.target.value)}/>
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <label> E-mail :</label>
                                    <input type='text' className='form-control'
                                    name='email' value={email} onChange={(e)=>setEmail(e.target.value)} />
                                </div>
                                <div className='col-sm-6'>
                                    <label>Contact :</label>
                                    <input type='text' className='form-control'
                                    name='contact' value={contact} onChange={(e)=>setContact(e.target.value)} />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    <label>Code postal :</label>
                                    <input type='text' className='form-control' 
                                    name='code_postal' value={code_postal} onChange={(e)=>setCode_postal(e.target.value)}/>
                                </div>
                                <div className='col-sm-6'>
                                    <label>Ville </label>
                                    <select value={selectedOption} onChange={handleSelectChange} className='form-control' name='selectedOption'>
                                        <option> </option>
                                        {options.map((option) => (
                                            <option key={option.id} value={option.id}>
                                                {option.province}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className='row opport'>
                            <div className='col-sm-6'>
                                <button className='retour' onClick={() => handleRetour()}>Retour</button>
                            </div>
                            <div className='col-sm-6'>
                                <button className='btn-primary btn_opport' type='submit' onClick={()=> {ajoutInscrits();}}>S'inscrire</button>
                            </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div>
                <Foot />
            </div>
        </div>
    )
}
