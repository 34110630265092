import React, { useState, useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import '../csssite/panier.css';

function Panier({ cartItems, updateCartItemQuantity, removeCartItem }) {
    // const totalPrice = cartItems.reduce((total, item) => total + parseInt(item.price) * (item.quantity || 1), 0);
    const totalPrice = cartItems.reduce((total, item) => {
        const totalParItem = parseInt(item.price) * (item.quantity || 1);
        return total + totalParItem;
      }, 0);
  
    return (
      <div>
        <div>
          <ul>
            {cartItems.map(item => (
              <li key={item.id} className='title'>
                <button className='delete-button' onClick={() => removeCartItem(item.id)}>
                <FaTimes style={{ color: 'red' }}/>
                </button>
                {item.title} : <span className='item-price' style={{ color: 'black' }}>
                                 {item.price}ar {' '}
                               </span>
                               <span className='x' style={{ color: 'black' }}> {' '}x</span> 
                <input
                  min={1}
                  type="number"
                  value={item.quantity || 1}
                  className='nombre'
                  onChange={(event) => {
                    const newQuantity = parseInt(event.target.value);
                    updateCartItemQuantity(item.id, newQuantity);
                  }}
                /><span className='tot' style={{ color: 'black' }}> = {parseInt(item.price) * (item.quantity || 1)}ar</span>
              </li>
            ))}
          </ul>
          <hr />
          <p className='tit'>TOTAL </p><p className='sis'>{totalPrice}ar</p>
        </div>
        <hr />
      </div>
    );
  }
  
  export default Panier;
  