import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import Connexion from './Backoffice/connexion';
import ProfilAgri from './Backoffice/profilAgri';
import Navbare from './Backoffice/navbar';
import './App.css';
import { Message } from './Backoffice/message';
import { ListeUser } from './Backoffice/listeUser';
import { Notification } from './Backoffice/notification';
import { Ajoutshop } from './Backoffice/ajoutshop';
import { Ajoutlocation } from './Backoffice/ajoutlocation';
import { Ajoutpartenaire } from './Backoffice/ajoutpartenaire';
import { Ajoutopportunite } from './Backoffice/ajoutopportunite';
import { Commune } from './Backoffice/parametre/commune';
import { Province } from './Backoffice/parametre/province';
import { Region } from './Backoffice/parametre/region';
import { Fokontany } from './Backoffice/parametre/fokontany';
import { Cooperative } from './Backoffice/parametre/cooperative';
import { District } from './Backoffice/parametre/district';
import { Logo } from './Backoffice/logo';
import { Accueil } from './Siteweb/jssite/accueil';
import { Apropos } from './Siteweb/jssite/apropos';
import { Compte } from './Siteweb/jssite/compte';
import { Location } from './Siteweb/jssite/location';
import { Contact } from './Siteweb/jssite/contact';
import { Foot } from './Siteweb/jssite/foot';
import { Naves } from './Siteweb/jssite/nav';
import { Opportunite } from './Siteweb/jssite/opportunite';
import { Shop } from './Siteweb/jssite/shop';
import { Categorie } from './Backoffice/parametre/categorie';
import Panie from './Siteweb/jssite/panie';
import { InscriptionF } from './Frontoffice/jsfront/InsriptionF';
import Navba from './Frontoffice/jsfront/nave';
import { InscriptionO } from './Siteweb/jssite/inscriptionO';

function App() {
  return (
    <div>
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Connexion/>}></Route>
        <Route path='/logo' element={<Logo/>}></Route>
        <Route path='/profilAgri' element={<ProfilAgri/>}></Route>
        <Route path='/navbar' element={<Navbare/>}></Route>
        <Route path='/message' element={<Message/>}></Route>
        <Route path='/notification' element={<Notification/>}></Route>
        <Route path='/listeUser' element={<ListeUser/>}></Route>
        <Route path='/ajoutshop' element={<Ajoutshop/>}></Route>
        <Route path='/ajoutlocation' element={<Ajoutlocation/>}></Route>
        <Route path='/ajoutpartenaire' element={<Ajoutpartenaire/>}></Route>
        <Route path='/ajoutopportunite' element={<Ajoutopportunite/>}></Route>
        <Route path='/commune' element={<Commune/>}></Route>
        <Route path='/region' element={<Region/>}></Route>
        <Route path='/province' element={<Province/>}></Route>
        <Route path='/fokontany' element={<Fokontany/>}></Route>
        <Route path='/cooperative' element={<Cooperative/>}></Route>
        <Route path='/district' element={<District/>}></Route>

        <Route path='/accueil' element={<Accueil/>}></Route>
        <Route path='/apropos' element={<Apropos/>}></Route>
        <Route path='/compte' element={<Compte/>}></Route>
        <Route path='/contact' element={<Contact/>}></Route>
        <Route path='/foot' element={<Foot/>}></Route>
        <Route path='/location' element={<Location/>}></Route>
        <Route path='/nav' element={<Naves/>}></Route>
        <Route path='/opportunite' element={<Opportunite/>}></Route>
        <Route path='/shop' element={<Shop/>}></Route>
        <Route path='/detail' element={<Panie/>}></Route>
        <Route path='/categorie' element={<Categorie/>}></Route>
        <Route path='/inscriptionF' element={< InscriptionF/>}></Route>
        <Route path='/naveba' element={<Navba/>}></Route>
        <Route path='/inscriptionOpp' element={<InscriptionO/>}></Route>
      </Routes>
    </BrowserRouter>
    </div>
  )
}

export default App;
