import React, {useState} from 'react'
import Navbare from './navbar';
import '../css/notification.css'

const notificationsData = [
  {
    id: 1,
    title: 'PAM',
    content: "PAM veut envoyer des messages aux 10 agriculteurs. Veuillez confirmer s'il vous plait",
  },
  {
    id: 2,
    title: 'ONG',
    content: 'Contenu de la deuxième notification.',
  },
  {
    id: 3,
    title: 'Expert',
    content: 'Contenu de la deuxième notification.',
  },
  
];

export const Notification = () => {

  const handleConfirm = () => {
 
    alert('Confirmation effectuée !');
  };
  
  const handleCancel = () => {
   
    alert('Annulation effectuée !');
  };

  const [selectedNotification, setSelectedNotification] = useState(null);

  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
  };

  const renderNotificationDetails = () => {
    if (selectedNotification) {
      return (
        <div className="notification-details">
          <h2>{selectedNotification.title}</h2>
          <p>{selectedNotification.content}</p>
          {selectedNotification.content && (
          <div className='row' style={{alignItems:'center'}}>
            <div className='col-sm-2'>
            <button onClick={handleConfirm} style={{backgroundColor:'blue', border:'none', color:'white', width:'80%'}}> Confirmer</button>
            </div>
            <div className='col-sm-2'>
            <button onClick={handleCancel}  style={{border:'none', width:'80%', backgroundColor:'dark'}}> Annuler</button>
            </div>
          </div>
        )}
        </div>
      );
    } else {
      return <div className="notification-details">Sélectionnez une notification pour voir les détails</div>;
    }
  };
  return (
    <div>
      <div>
        <Navbare/>
      </div> <br></br>
      <h3 className='not'>NOTIFICATION</h3>
        <div className="App">
      <div className="notification-list"> 
        <ul>
          {notificationsData.map((notification) => (
            <li key={notification.id} onClick={() => handleNotificationClick(notification)}>
              {notification.title}
            </li>
          ))}
        </ul>
      </div>
      {renderNotificationDetails()}
    </div>
    </div>
  )
}
