import React from 'react';
import {useState, useEffect} from 'react';
import { Naves } from './nav';
import axios from 'axios';
import {Foot} from './foot';
import "../csssite/apropos.css";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  EffectCube,
  Autoplay,
} from "swiper/modules";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/navigation";
import "swiper/css/pagination";

export const Apropos = () => {

  const [records, setRecords] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("http://localhost:100/partenaire");
        setRecords(response.data);
      } catch (error) {
        console.error("Erreur lors de la récupération des données : ", error);
      }
    };
  
    fetchData(); // Appelez la fonction pour récupérer les données au chargement du composant
  }, []);

  return (
    <div>
        <Naves/>
      <div className="container pr">
        <p className="text-danger text-center">À propos de nous</p>
      </div>
      <div className='card'>
        <div className='row'>
          <div className='col-sm-6'>
            <img src="./images/leg.jpg" className="responsive bur" alt='apropos'></img>
          </div>
          <div className='col-sm-6'><br></br>
            <p className="text-center">MIHARO est une plateforme losm hdjasjdgadjg<br></br>
              jhvdshvsjvsuadv.<br></br>
              vjhxcvjhvhxjvhxjvhxjvhxkjvhxjvhxjvhxjvhjxzhvyudfyuakfhsahfhsi,<br></br>
              cvzxghvgzvgxhcvgxjzvgxvxhzgcvystfyagvfsdhjfghjsgdsfgsdufbhsjgcyusGc.
            </p>
            <p className="text-center">jhsdgshfgshfgshjfgsjfgsjfgsjagftyafhjasfhjasgfhjsagf<br></br>
              vfdhjkxhjvchxjvxhyvuxzhxxzjzcghjzgycuzcjzchyuzctsu,<br></br>
              kjscvhjxczhcvjxzhcvjhzcvjhzcjcvhxzczcgjzgyu.
            </p>
            <p className="text-center">
              dvhjdxzvhjxchvjkhxckjhvkjhvkxjzvhkjxzvhuiiyfvuszfhnjkshzcjkhzkjcnzkjcjkh<br></br>
              dsfhsdjjjhgjdhfjshfjdshfushudsfhsdjfhdsfusdi,<br></br>
              uxzucuysiufdsifydsiufysdfsdfusfsdifysiduyfsiufys.
            </p>

          </div>
        </div>

      </div><br></br>

      <div className="container">
        <div className="par">
          <p>Partenaires</p>
        </div>
        <div className="container container_partenaire row">
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              EffectCube,
              Autoplay,
            ]}
            style={{zIndex:0}}
            spaceBetween={30}
            slidesPerView={4}
            // navigation={{}}
            autoplay={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {records.map((item) => (
              <SwiperSlide key={item.id}>
                <div className="sous_container_partenaire col pe-0 mt-4 mb-5">
                  <div className="photo_partenaire col">
                    <img src={item.logo} alt="partenaire" />
                    <div />
                    {/* <div className="contenu_partenaire">
                      <div className="titres">
                        <p className="text-white piza">Pizzamania</p>
                      </div>
                    </div> */}
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
      <div>
        <Foot/>
      </div>
      
    </div>
  )
}
