import React, { Component } from 'react';
import "bootstrap/dist/css/bootstrap.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import "../csssite/nav.css";
import { Nav} from 'react-bootstrap';
import {Link} from 'react-router-dom';

export class Naves extends Component {
  state = {clicked : false};
  handleClick = () =>{
    this.setState({clicked:!this.state.clicked})
  }
  render(){
    const iconColors = this.state.clicked ? 'green' : 'green';
    // const logoStyles = {
    //   width: '110px', // Largeur de l'image
    //   height:'56.5px'
    // };
  return (
    <div>
    <Nav className='nave'>
      <Link to='/logo'>
       <img src="./images/log.jpg" alt='responsive' className='logoStyles'></img> 
       </Link>
      <div>
        <ul id='navbare' className={this.state.clicked ? "#navbare active" : "#navbare"}>

          <li> <Link className="active to_link" to='/accueil'>Accueil</Link></li>
          <li><Link to='/shop' className='to_link'>Shop</Link></li>
          <li><Link to='/location'className='to_link'>Nos matériaux</Link></li>
          <li><Link to='/opportunite' className='to_link'>Opportunité</Link></li>
          <li><Link to='/apropos' className = 'to_link'>À propos</Link></li>
          <li><Link to='/contact' className='to_link'>Contact</Link></li>
          <li className="dropdown"> Compte
          <div className="dropdown-contente">
             <Link to="/compte" className='to_link'>Informations personnelle</Link>
             <Link to="/accueil" className='to_link'>Se déconnecter</Link>
           </div>
       </li>

        </ul>
      </div>
      <div id="mobile" onClick={this.handleClick}>
        <FontAwesomeIcon icon={this.state.clicked ? faTimes :
        faBars} style={{ marginLeft: '-100%', marginTop:'40%' ,cursor: 'pointer', fontSize: '27px', color: iconColors}}/>
      </div>
    </Nav>
  </div>
  )
}
}


