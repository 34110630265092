import { useState, useEffect } from 'react';
import "../csssite/shop.css";
import 'bootstrap/dist/css/bootstrap.css';
import { HiShoppingCart } from 'react-icons/hi';
import { Form, Button, Modal } from 'react-bootstrap';
import { Naves } from './nav';
import { Foot } from './foot'
import axios from 'axios';
import Panier from './panier';
import { FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';




export const Shop = () => {

    const [records, setRecords] = useState([]);
    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://localhost:100/shop");
                setRecords(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données : ", error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get("http://localhost:100/categorie");
                setCategories(response.data);
            } catch (error) {
                console.error("Erreur lors de la récupération des données : ", error);
            }
        };

        fetchData();
    }, []);


    const columns = 4;
    const dataChunks = [];

    for (let i = 0; i < records.length; i += columns) {
        dataChunks.push(records.slice(i, i + columns));
    }

    const [consoleProducts, setConsoleProducts] = useState([]);
    const [cartItems, setCartItems] = useState([]);

    const addToConsole = (item) => {
        const isProductAlreadyInConsole = consoleProducts.some(existingItem => existingItem.id === item.id);
        if (isProductAlreadyInConsole) {
            console.log('Le produit est déjà présent dans la console.');
        } else {
            setConsoleProducts([...consoleProducts, item]);
            console.log(item.id, item.title, item.price);
        }
    };

    const addToCart = (item) => {
        setCartItems((prevCartItems) => {
            const isProductAlreadyInCart = prevCartItems.some(existingItem => existingItem.id === item.id);
            if (isProductAlreadyInCart) {
                console.log(`Le produit est déjà présent dans le panier. Retirer l'article - ID: ${item.id}`);

                const updatedCartItems = prevCartItems.filter(existingItem => existingItem.id !== item.id);
                return updatedCartItems;
            } else {
                console.log(`Produit ajouté au panier - ID: ${item.id}, Titre: ${item.title}, Prix: ${item.price}`);
                return [...prevCartItems, item];
            }
        });
    };


    const [showAdd, setShowAdd] = useState(false);

    const handleClosemodalAdd = () => setShowAdd(false);
    const handleShowModalAdd = () => {
        setShowAdd(true);
        setShowPanier(true);
    }

    const updateCartItemQuantity = (itemId, newQuantity) => {
        const updatedCartItems = cartItems.map(item => {
            if (item.id === itemId) {
                return { ...item, quantity: newQuantity };
            }
            return item;
        });

        setCartItems(updatedCartItems);
    };

    const removeCartItem = (itemId) => {
        const updatedCartItems = cartItems.filter(item => item.id !== itemId);
        setCartItems(updatedCartItems);
        return false;
    };

    const [showPanier, setShowPanier] = useState(false);


    const [showmodalAdd, setShowModalAdd] = useState(false);

    const HandleCloseModalAdd = () => {
        setShowModalAdd(false);
    };

    const HandleShowModalAdd = () => {
        setShowModalAdd(true);
        setShowAdd(false);

    };

    const [clickedIcons, setClickedIcons] = useState(records.map(() => false));

    const handleClick = (rowIndex) => {
        const newClickedIcons = [...clickedIcons];
        newClickedIcons[rowIndex] = !newClickedIcons[rowIndex];
        setClickedIcons(newClickedIcons);
    };

    const filteredRecords = selectedCategory
    ? records.filter((record) => record.categorie === selectedCategory)
    : records;

    return (
        <>
            <Naves />
            <div style={{ backgroundColor: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                <div className="container-fluid">
                    <div>
                        <button className='btne text-body' onClick={handleShowModalAdd}>Panier<HiShoppingCart className='panie' /></button>
                    </div>
                    <Modal className="special_modal" show={showAdd} onHide={handleClosemodalAdd} style={{ backgroundColor: 'rgba(255, 255, 255, 0.3)' }}>
                        <Modal.Header className="Modal_Login_header">
                            <Modal.Title className='pant'>PANIER </Modal.Title>
                            <span style={{ fontSize: '250%', color: 'green', marginRight: '30%' }}><HiShoppingCart /></span>
                            <i className="Close" onClick={() => handleClosemodalAdd(true)}><FaTimes /></i>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>{showPanier && <Panier cartItems={cartItems} updateCartItemQuantity={updateCartItemQuantity} removeCartItem={removeCartItem} />}</Form.Label>
                                </Form.Group>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className='footer'>
                            <div className='row command'>
                                <div className='col-sm-6'>
                                    <button className='annuler' onClick={handleClosemodalAdd}>
                                        Annuler
                                    </button>
                                </div>
                                <div className='col-sm-6'>
                                    <button onClick={HandleShowModalAdd}>
                                        Commander
                                    </button>
                                </div>
                            </div>
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        show={showmodalAdd}
                        onHide={HandleCloseModalAdd}
                        dialogClassName="custom-modal"
                        className="special_modal">
                        <Modal.Header className="Modal_Login_header" >
                            <i className="Close" onClick={() => HandleCloseModalAdd(true)}><FaTimes /></i>
                        </Modal.Header>
                        <Modal.Body>
                            <div>
                                <p>Merci pour votre confiance , on va vous contacter pour livrer votre commande</p>
                            </div>
                        </Modal.Body>
                        <Modal.Footer className='footer'>
                            <Button onClick={HandleCloseModalAdd}>
                                Terminer
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <div>
                        <div style={{ width: '100%', paddingLeft: '4%' }}>
                            <div style={{ width: '100%', paddingLeft: '4%' }}>

                                <div className='form-group row categorie'>
                                <div className='col-sm-2'>
    <p>
      <Link to="#" onClick={() => setSelectedCategory(null)}>TOUT</Link>
    </p>
  </div>
                                    {categories.map((category) => (
                                        <div className='col-sm-2' key={category.id}>
                                            <p>
                                                <Link
                                                    to="#"
                                                    onClick={() => setSelectedCategory(category.categorie)}
                                                >
                                                    {category.categorie}
                                                </Link>
                                            </p>
                                        </div>
                                    ))}
                                    </div>


                                    <div >
                                        <div className='row dati'>
                                            {filteredRecords.map((item, rowIndex) => (
                                                <div className='col-lg-3 col-md-6' key={rowIndex}>
                                                    <div className='card custom-card text-white' style={{ width: '80%', height: '100%' }}>
                                                        <img src={item.image} className='piz' alt='Pizza' style={{ objectFit: 'cover', width: '80%', height: '50%' }} />
                                                        <div className='nom'>
                                                            <p className='text-uppercase'>{item.title}</p>
                                                        </div>
                                                        <p className='text-body p'>{item.price} ar/kg <i onClick={() => { addToConsole(item); addToCart(item); }}>
                                                            <HiShoppingCart
                                                                className={`panier ${clickedIcons[rowIndex] ? 'clicked' : ''}`}
                                                                onClick={() => handleClick(rowIndex)} /></i></p>
                                                        <p className='des'>({item.detail})</p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <Foot />
            </>
            );
}
