import React, { useState, useEffect } from 'react'
import Navbare from '../navbar';
import '../../css/district.css';
import DataTable from 'react-data-table-component';
import axios from 'axios';
import { MdDeleteOutline } from "react-icons/md";
import { AiTwotoneEdit } from "react-icons/ai";
import url from '../../url';


export const District = () => {

const [records, setRecords] = useState([]);
const [editingData, setEditingData] = useState(null);
const [isEditing, setIsEditing] = useState(false);

const handleInput = (e) => {
    const { name, value } = e.target;
    setEditingData({ ...editingData, [name]: value });
};

const CustomActions = ({ row }) => {

    const handleDelete = (id) => {
        const confirmDelete = window.confirm("Voulez-vous vraiment supprimer ?");
        if (confirmDelete) {
          axios
            .delete(`http://localhost:100/district/${id}`)
            .then((response) => {
                alert("Suppression reussi");
                afficheDistrict();
              })
            .catch((error) => {
              console.error("Erreur lors de la suppression :", error);
              window.alert("Erreur lors de la suppression : " + error.message);
            });
        }
      };
    
      const handleEdit = (row) => {
        setEditingData(row);
        setIsEditing(true);
    };

    return (
        <div><button type='button' className='icon edit'><AiTwotoneEdit onClick={() => handleEdit(row)} /></button>
            <button type='button' className='icon delete'><MdDeleteOutline onClick={() => handleDelete(row.id)} /></button>

        </div>
    );
};

const columns = [
    {
        name: "RÉGION",
        selector: (row) => row.regions.region,
        sortable: true,
    },
    {
        name: "DISTRICT",
        selector: (row) => row.district,
        sortable: true,
    },
    {
        name: 'Actions',
        cell: row => <CustomActions row={row} />,
    },
];

const afficheDistrict = async () =>{
    url
    .get("/district/")
    .then((res)=> setRecords(res.data))
    .catch((err) => console.log(err));
};
useEffect(()=>{
    afficheDistrict();
},[]);

const [selectedOption, setSelectedOption] = useState('');
const [regions, setRegions] = useState([]);
const[district, setDistrict]= useState('');
const[districts, setDistricts]= useState('');

const handleSubmit = (e) =>{
    e.preventDefault();
}

const handleAdd = () => {
    if (isEditing) {

        axios
            .put(`http://localhost:100/district/${editingData.id}`, {
                id_region: editingData.id_region,
                district: editingData.district,
            })
            .then((res) => {
                setIsEditing(false);
                setEditingData({
                    id_region:'',
                    district: '',
                });
                window.alert("Mise à jour réussie");
                afficheDistrict();
            })
            .catch((error) => {
                console.error("Erreur lors de la mise à jour :", error);
                window.alert("Erreur lors de la mise à jour : " + error.message);
            });
    } else {
    axios
    .post(`http://localhost:100/district/`,
    {
        id_region: selectedOption,
        district: district,
    }
    )
    .then((res)=>{
        setDistricts ([...districts, res.data]);
        alert("ajout avec succès");
        afficheDistrict();
        setDistrict("");
        setSelectedOption("");
    })
    }
};

useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await axios.get("http://localhost:100/region");
            setRegions(response.data); // Supposons que les options sont renvoyées sous forme de tableau depuis votre API
        } catch (error) {
            console.error("Erreur lors de la récupération des options : ", error);
        }
    };

    fetchData();
}, []);


  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  }

    return (
        <div className="content-wrapper">
            <div className="content-header">
                <div className="container-fluid">
                    <Navbare />
                    <form onSubmit={handleSubmit}>
                    <div className="row mb-2 top">
                        <div className="col-sm-6">
                            <p className="m-0 parametr">AJOUT DISTRICT</p> <br></br>
                            { editingData ? (
                                <>
                            <div className='lab'>
                                <label>RÉGION:</label>
                                <select value={editingData.id_region} onChange={handleInput} className='jo' name='id_region'>
                                {regions.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.region}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='lab'>
                                <label>DISTRICT :</label>
                                <input className='jout' name='district' value={editingData.district} onChange={handleInput} />
                            </div></>
                            ) : (
                                <>
                                <div className='lab'>
                                <label>RÉGION:</label>
                                <select value={selectedOption} onChange={handleSelectChange} className='jo'>
                                    <option> </option>
                                {regions.map((option) => (
                                  <option key={option.id} value={option.id}>
                                    {option.region}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className='lab'>
                                <label>DISTRICT :</label>
                                <input className='jout' name='district' value={district} onChange={(e)=>setDistrict(e.target.value)} />
                            </div>
                                </>
                            )}
                            <div className='btn-part'>
                                <button className='btn btn-primary' type='submit' onClick={handleAdd}>{isEditing ? 'Modifier' :'Ajouter'}</button>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className='parametre'>
                                <p>LISTE DES DISTRICTS</p>
                            </div>
                            <div className='tabl'>
                                <DataTable
                                    columns={columns}
                                    data={records}
                                    pagination
                                // highlightOnHover
                                /></div>
                        </div>
                    </div>
                    </form>
                </div>
            </div>
            {/* <Footer/> */}
        </div>

    )
}
