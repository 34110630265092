import React, { useState} from 'react';
import '../cssfront/inscriptionF.css';
import axios from 'axios';
import {AiOutlineEyeInvisible} from 'react-icons/ai';
import {AiOutlineEye} from 'react-icons/ai';

export const InscriptionF = ()=> {

  const [denomination, setDenomination]= useState('');
  const [activite_entite, setActivite_entite]= useState('');
  const [adresse, setAdresse]= useState('');
  const [num_tel, setNum_tel]= useState('');
  const [email, setEmail]= useState('');
  const [mot_de_passe, setMot_de_passe]= useState('');
  const [mot_de_passe1, setMot_de_passe1]= useState('');
  const [users, setUsers] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const handleSubmit = (e) =>{
    e.preventDefault();
  }
  
  const ajoutUser = () => {
    axios
      .post(
        "http://localhost:100/user",
        {
         denomination : denomination,
         activite_entite: activite_entite,
         adresse: adresse,
         num_tel: num_tel,
         email:email,
         mot_de_passe:mot_de_passe,
         mot_de_passe1: mot_de_passe1
        }
      )
      .then((res) => {
        setUsers([...users, res.data]);
        alert("Veuillez verifier votre email s'il vous plait");
        setDenomination('');
        setActivite_entite('');
        setAdresse('');
        setNum_tel('');
        setEmail('');
        setMot_de_passe('');
        setMot_de_passe1('');
      })
      .catch((error) => {
        console.log(error);
      });

  }

    return (
      <div className='form_inscri'>
        <div className='row'>
          <div className='col-sm-6'>
          <img src="./images/log.jpg" alt='responsive' className='image' />
          </div>
          <div className='col-sm-6 card_formu'>
        <form onSubmit={handleSubmit}>
          <h2 className='text-center text-primary formulaire'>Formulaire d'inscription</h2>
        <div className='row'>
        <div className='col-sm-6'>
          <label className='denom'>Dénomination :</label>
          <input className='form-control' type='text'
          name='deanomination' value={denomination}
          onChange={(e)=>setDenomination(e.target.value)}/>
        </div>
        <div className='col-sm-6'>
          <label>Activités d'entité :</label>
          <input className='form-control'
          name='activite_entite' value={activite_entite} type='text'
          onChange={(e)=> setActivite_entite(e.target.value)}/>
        </div>
        </div>
        <div className='row'>
        <div className='col-sm-6'>
          <label>Adresse :</label>
          <input className='form-control' type='text'
          name='adresse' value={adresse}
          onChange={(e)=> setAdresse(e.target.value)}/>
        </div>
        <div className='col-sm-6'>
          <label>Contact :</label>
          <input className='form-control' type='text'
          name='num_tel' value={num_tel} 
          onChange={(e)=> setNum_tel(e.target.value)}/>
        </div>
        </div>
        <div className='row'>
        <div className='col-sm-6'>
          <label>E-mail :</label>
          <input className='form-control' type='text'
          name='email' value={email}
          onChange={(e)=> setEmail(e.target.value)}/>
        </div>
        <div className='col-sm-6'>
          <label>Mot de passe :</label>
          <div className="password-input">
        <input className='form-control' type={showPassword ? 'text' : 'password'} name='mot_de_passe' value={mot_de_passe} onChange={(e) => setMot_de_passe(e.target.value)} />
        <span className="icone-inside-input password-toggle eye" onClick={() => setShowPassword(!showPassword)}>
          {showPassword ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
        </span>
      </div>
        </div>
        </div>
        <div className='row'>
        <div className='col-sm-6'>
          <label>Confirmer mot de passe :</label>
          <div className="password-input">
        <input className='form-control' type={showPassword1 ? 'text' : 'password'} name='mot_de_passe1' value={mot_de_passe1} onChange={(e) => setMot_de_passe1(e.target.value)} />
        <span className="icon-inside-input password-toggle eye" onClick={() => setShowPassword1(!showPassword1)}>
          {showPassword1 ? <AiOutlineEyeInvisible/> : <AiOutlineEye/>}
        </span>
      </div>
        </div>
        <div className='col-sm-6 btn_inscr'>
    
        <button type='submit' className='inscrir' onClick={()=> {ajoutUser();}}>S'inscrire</button>

        </div>
        </div>
        </form>
        </div>
        </div>
      </div>
      );
    }